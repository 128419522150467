.klaro {
  --border-radius: 0;
  --white2: #cccc;

  .cookie-notice {
    max-width: 700px !important;
  }

  .cookie-modal,
  .cookie-notice {
    a {
      color: $yellow !important;
    }

    .cm-btn {
      background: $yellow !important;
      border-radius: 0 !important;
      color: $blue !important;
    }

    .cm-app-input:checked + .cm-app-label .slider {
      background-color: $green !important;
    }
  }

  &.cm-as-context-notice {
    padding: 0 !important;
  }
}

.embed-responsive [data-type="placeholder"] {
  @extend .embed-responsive-item;
  height: 100% !important;
  max-width: 100% !important;
}
