.rte {
	p, img {
		margin-bottom: 1.25rem;
	}
	img.large {
		@include box-shadow;
		@extend %mt-xl;
		@extend %mb-xl;
		@media (min-width: $grid-breakpoint-md) {
			max-width: calc( 100% + 34vw);
			margin-left: -17vw;
		}
		@media (min-width: $grid-breakpoint-md) {
			max-width: calc( 100% + 34vw);
			margin-left: -17vw;
		}
		@media (min-width: $grid-breakpoint-lg) {
			max-width: calc( 100% + 18vw);
			margin-left: -9vw;
		}
		@media (min-width: $grid-breakpoint-xl) {
			max-width: calc( 100% + 33vw);
			margin-left: -16.5vw;
		}
	}
	[class*=display-]:not(:first-child) {
		margin-top: 2.75rem;
	}
	p:last-child {
		margin-bottom: 0;
	}
	a {
		text-decoration: underline;
		@include transition-timing;
		@include transition-property(color, background-color);
	}
	a:hover {
		text-decoration: none;
	}
	p:not(.text-tiny),
	.rte img {
		margin-bottom: 1.25rem;
	}
	.text-tiny + [class*=display-] {
		margin-top: 0 !important;
	}
}




.text-green {
	.rte {
		a {
			color:$green;
		}
		a:hover {
			background-color: $green;
		}
	}
}
.text-blue {
	.rte {
		a {
			color:$blue;
		}
		a:hover {
			background-color: $blue;
		}
	}
}
.text-yellow {
	.rte {
		a {
			color:$yellow;
		}
		a:hover {
			background-color: $yellow;
		}
	}
}
.text-violet {
	.rte {
		a {
			color:$violet;
		}
		a:hover {
			background-color: $violet;
		}
	}
}
.text-white {
	.rte {
		a {
			color:$white;
		}
		a:hover {
			background-color: $white;
		}
	}
}
[data-color="green"] {
	.rte {
		a:hover {
			color: $green;
		}
	}
}
[data-color="blue"] {
	.rte {
		a:hover {
			color: $blue;
		}
	}
}
[data-color="yellow"] {
	.rte {
		a:hover {
			color: $yellow;
		}
	}
}
[data-color="white"] {
	.rte {
		a:hover {
			color: $white;
		}
	}
}