

$spacing-xxl: 		5rem; 		// 80px
$spacing-xl: 		3.75rem; 	// 60px
$spacing-l: 		1.25rem;	// 20px
$spacing-m: 		1.24rem;	// 20px
$spacing-s: 		1rem;		// 16px
$spacing-xs: 		0.625rem;	// 10px

$spacing-md-xxl: 	7.5rem; 	// 120px
$spacing-md-xl: 	5rem; 		// 80px
$spacing-md-l: 		1.875rem; 	// 30px
$spacing-md-m: 		1.875rem; 	// 30px
$spacing-md-s: 		1.25rem;	// 20px
$spacing-md-xs: 	0.625rem;	// 10px

$spacing-xl-xxl: 	12.5rem; 	// 200px
$spacing-xl-xl: 	7.5rem; 	// 120px
$spacing-xl-l: 		3.125rem; 	// 50px
$spacing-xl-m: 		1.875rem; 	// 30px
$spacing-xl-s: 		1.25rem;	// 20px
$spacing-xl-xs: 	0.625rem;	// 10px


/* -- MARGINS ----------------------------------------------------------- */

.mt-xxl, %mt-xxl 		{ margin-top: $spacing-xxl; }
.mt-xl,	%mt-xl 			{ margin-top: $spacing-xl; }
.mt-l, %mt-l 			{ margin-top: $spacing-l; }
.mt-m, %mt-m 			{ margin-top: $spacing-m; }
.mt-s, %mt-s 			{ margin-top: $spacing-s; }
.mt-xs, %mt-xs 			{ margin-top: $spacing-xs; }

.mb-xxl, %mb-xxl 		{ margin-bottom: $spacing-xxl; }
.mb-xl, %mb-xl 			{ margin-bottom: $spacing-xl; }
.mb-l, %mb-l 			{ margin-bottom: $spacing-l; }
.mb-m, %mb-m 			{ margin-bottom: $spacing-m; }
.mb-s, %mb-s 			{ margin-bottom: $spacing-s; }
.mb-xs, %mb-xs 			{ margin-bottom: $spacing-xs; }

.ml-xxl, %ml-xxl 		{ margin-left: $spacing-xxl; }
.ml-xl, %ml-xl 			{ margin-left: $spacing-xl; }
.ml-l, %ml-l 			{ margin-left: $spacing-l; }
.ml-m, %ml-m 			{ margin-left: $spacing-m; }
.ml-s, %ml-s 			{ margin-left: $spacing-s; }
.ml-xs, %ml-xs 			{ margin-left: $spacing-xs; }

.mr-xxl, %mr-xxl 		{ margin-right: $spacing-xxl; }
.mr-xl, %mr-xl 			{ margin-right: $spacing-xl; }
.mr-l, %mr-l 			{ margin-right: $spacing-l; }
.mr-m, %mr-m 			{ margin-right: $spacing-m; }
.mr-s, %mr-s 			{ margin-right: $spacing-s; }
.mr-xs, %mr-xs 			{ margin-right: $spacing-xs; }

@media (min-width: $grid-breakpoint-md) {
	.mt-xxl, %mt-xxl 		{ margin-top: $spacing-md-xxl; }
	.mt-xl,	%mt-xl 			{ margin-top: $spacing-md-xl; }
	.mt-l, %mt-l 			{ margin-top: $spacing-md-l; }
	.mt-m, %mt-m 			{ margin-top: $spacing-md-m; }
	.mt-s, %mt-s 			{ margin-top: $spacing-md-s; }
	.mt-xs, %mt-xs 			{ margin-top: $spacing-md-xs; }

	.mb-xxl, %mb-xxl 		{ margin-bottom: $spacing-md-xxl; }
	.mb-xl, %mb-xl 			{ margin-bottom: $spacing-md-xl; }
	.mb-l, %mb-l 			{ margin-bottom: $spacing-md-l; }
	.mb-m, %mb-m 			{ margin-bottom: $spacing-md-m; }
	.mb-s, %mb-s 			{ margin-bottom: $spacing-md-s; }
	.mb-xs, %mb-xs 			{ margin-bottom: $spacing-md-xs; }

	.ml-xxl, %ml-xxl 		{ margin-left: $spacing-md-xxl; }
	.ml-xl, %ml-xl 			{ margin-left: $spacing-md-xl; }
	.ml-l, %ml-l 			{ margin-left: $spacing-md-l; }
	.ml-m, %ml-m 			{ margin-left: $spacing-md-m; }
	.ml-s, %ml-s 			{ margin-left: $spacing-md-s; }
	.ml-xs, %ml-xs 			{ margin-left: $spacing-md-xs; }

	.mr-xxl, %mr-xxl 		{ margin-right: $spacing-md-xxl; }
	.mr-xl, %mr-xl 			{ margin-right: $spacing-md-xl; }
	.mr-l, %mr-l 			{ margin-right: $spacing-md-l; }
	.mr-m, %mr-m 			{ margin-right: $spacing-md-m; }
	.mr-s, %mr-s 			{ margin-right: $spacing-md-s; }
	.mr-xs, %mr-xs 			{ margin-right: $spacing-md-xs; }
}

@media (min-width: $grid-breakpoint-xl) {
	.mt-xxl, %mt-xxl 		{ margin-top: $spacing-xl-xxl; }
	.mt-xl,	%mt-xl 			{ margin-top: $spacing-xl-xl; }
	.mt-l, %mt-l 			{ margin-top: $spacing-xl-l; }
	.mt-m, %mt-m 			{ margin-top: $spacing-xl-m; }
	.mt-s, %mt-s 			{ margin-top: $spacing-xl-s; }
	.mt-xs, %mt-xs 			{ margin-top: $spacing-xl-xs; }

	.mb-xxl, %mb-xxl 		{ margin-bottom: $spacing-xl-xxl; }
	.mb-xl, %mb-xl 			{ margin-bottom: $spacing-xl-xl; }
	.mb-l, %mb-l 			{ margin-bottom: $spacing-xl-l; }
	.mb-m, %mb-m 			{ margin-bottom: $spacing-xl-m; }
	.mb-s, %mb-s 			{ margin-bottom: $spacing-xl-s; }
	.mb-xs, %mb-xs 			{ margin-bottom: $spacing-xl-xs; }

	.ml-xxl, %ml-xxl 		{ margin-left: $spacing-xl-xxl; }
	.ml-xl, %ml-xl 			{ margin-left: $spacing-xl-xl; }
	.ml-l, %ml-l 			{ margin-left: $spacing-xl-l; }
	.ml-m, %ml-m 			{ margin-left: $spacing-xl-m; }
	.ml-s, %ml-s 			{ margin-left: $spacing-xl-s; }
	.ml-xs, %ml-xs 			{ margin-left: $spacing-xl-xs; }

	.mr-xxl, %mr-xxl 		{ margin-right: $spacing-xl-xxl; }
	.mr-xl, %mr-xl 			{ margin-right: $spacing-xl-xl; }
	.mr-l, %mr-l 			{ margin-right: $spacing-xl-l; }
	.mr-m, %mr-m 			{ margin-right: $spacing-xl-m; }
	.mr-s, %mr-s 			{ margin-right: $spacing-xl-s; }
	.mr-xs, %mr-xs 			{ margin-right: $spacing-xl-xs; }
}

/* -- PADDINGS ----------------------------------------------------------- */

.pt-xxl, %pt-xxl 		{ padding-top: $spacing-xxl; }
.pt-xl,	%pt-xl 			{ padding-top: $spacing-xl; }
.pt-l, %pt-l 			{ padding-top: $spacing-l; }
.pt-m, %pt-m 			{ padding-top: $spacing-m; }
.pt-s, %pt-s 			{ padding-top: $spacing-s; }
.pt-xs, %pt-xs 			{ padding-top: $spacing-xs; }

.pb-xxl, %pb-xxl 		{ padding-bottom: $spacing-xxl; }
.pb-xl, %pb-xl 			{ padding-bottom: $spacing-xl; }
.pb-l, %pb-l 			{ padding-bottom: $spacing-l; }
.pb-m, %pb-m 			{ padding-bottom: $spacing-m; }
.pb-s, %pb-s 			{ padding-bottom: $spacing-s; }
.pb-xs, %pb-xs 			{ padding-bottom: $spacing-xs; }

.pl-xxl, %pl-xxl 		{ padding-left: $spacing-xxl; }
.pl-xl, %pl-xl 			{ padding-left: $spacing-xl; }
.pl-l, %pl-l 			{ padding-left: $spacing-l; }
.pl-m, %pl-m 			{ padding-left: $spacing-m; }
.pl-s, %pl-s 			{ padding-left: $spacing-s; }
.pl-xs, %pl-xs 			{ padding-left: $spacing-xs; }

.pr-xxl, %pr-xxl 		{ padding-right: $spacing-xxl; }
.pr-xl, %pr-xl 			{ padding-right: $spacing-xl; }
.pr-l, %pr-l 			{ padding-right: $spacing-l; }
.pr-m, %pr-m 			{ padding-right: $spacing-m; }
.pr-s, %pr-s 			{ padding-right: $spacing-s; }
.pr-xs, %pr-xs 			{ padding-right: $spacing-xs; }

@media (min-width: $grid-breakpoint-md) {
	.pt-xxl, %pt-xxl 		{ padding-top: $spacing-md-xxl; }
	.pt-xl,	%pt-xl 			{ padding-top: $spacing-md-xl; }
	.pt-l, %pt-l 			{ padding-top: $spacing-md-l; }
	.pt-m, %pt-m 			{ padding-top: $spacing-md-m; }
	.pt-s, %pt-s 			{ padding-top: $spacing-md-s; }
	.pt-xs, %pt-xs 			{ padding-top: $spacing-md-xs; }

	.pb-xxl, %pb-xxl 		{ padding-bottom: $spacing-md-xxl; }
	.pb-xl, %pb-xl 			{ padding-bottom: $spacing-md-xl; }
	.pb-l, %pb-l 			{ padding-bottom: $spacing-md-l; }
	.pb-m, %pb-m 			{ padding-bottom: $spacing-md-m; }
	.pb-s, %pb-s 			{ padding-bottom: $spacing-md-s; }
	.pb-xs, %pb-xs 			{ padding-bottom: $spacing-md-xs; }

	.pl-xxl, %pl-xxl 		{ padding-left: $spacing-md-xxl; }
	.pl-xl, %pl-xl 			{ padding-left: $spacing-md-xl; }
	.pl-l, %pl-l 			{ padding-left: $spacing-md-l; }
	.pl-m, %pl-m 			{ padding-left: $spacing-md-m; }
	.pl-s, %pl-s 			{ padding-left: $spacing-md-s; }
	.pl-xs, %pl-xs 			{ padding-left: $spacing-md-xs; }

	.pr-xxl, %pr-xxl 		{ padding-right: $spacing-md-xxl; }
	.pr-xl, %pr-xl 			{ padding-right: $spacing-md-xl; }
	.pr-l, %pr-l 			{ padding-right: $spacing-md-l; }
	.pr-m, %pr-m 			{ padding-right: $spacing-md-m; }
	.pr-s, %pr-s 			{ padding-right: $spacing-md-s; }
	.pr-xs, %pr-xs 			{ padding-right: $spacing-md-xs; }
}

@media (min-width: $grid-breakpoint-xl) {
	.pt-xxl, %pt-xxl 		{ padding-top: $spacing-xl-xxl; }
	.pt-xl,	%pt-xl 			{ padding-top: $spacing-xl-xl; }
	.pt-l, %pt-l 			{ padding-top: $spacing-xl-l; }
	.pt-m, %pt-m 			{ padding-top: $spacing-xl-m; }
	.pt-s, %pt-s 			{ padding-top: $spacing-xl-s; }
	.pt-xs, %pt-xs 			{ padding-top: $spacing-xl-xs; }

	.pb-xxl, %pb-xxl 		{ padding-bottom: $spacing-xl-xxl; }
	.pb-xl, %pb-xl 			{ padding-bottom: $spacing-xl-xl; }
	.pb-l, %pb-l 			{ padding-bottom: $spacing-xl-l; }
	.pb-m, %pb-m 			{ padding-bottom: $spacing-xl-m; }
	.pb-s, %pb-s 			{ padding-bottom: $spacing-xl-s; }
	.pb-xs, %pb-xs 			{ padding-bottom: $spacing-xl-xs; }

	.pl-xxl, %pl-xxl 		{ padding-left: $spacing-xl-xxl; }
	.pl-xl, %pl-xl 			{ padding-left: $spacing-xl-xl; }
	.pl-l, %pl-l 			{ padding-left: $spacing-xl-l; }
	.pl-m, %pl-m 			{ padding-left: $spacing-xl-m; }
	.pl-s, %pl-s 			{ padding-left: $spacing-xl-s; }
	.pl-xs, %pl-xs 			{ padding-left: $spacing-xl-xs; }

	.pr-xxl, %pr-xxl 		{ padding-right: $spacing-xl-xxl; }
	.pr-xl, %pr-xl 			{ padding-right: $spacing-xl-xl; }
	.pr-l, %pr-l 			{ padding-right: $spacing-xl-l; }
	.pr-m, %pr-m 			{ padding-right: $spacing-xl-m; }
	.pr-s, %pr-s 			{ padding-right: $spacing-xl-s; }
	.pr-xs, %pr-xs 			{ padding-right: $spacing-xl-xs; }
}