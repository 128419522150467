// Background Colors

%bg-green,
.bg-green {
	background-color: $green;
}
%bg-blue,
.bg-blue {
	background-color: $blue;
}
%bg-yellow,
.bg-yellow {
	background-color: $yellow;
}
%bg-violet,
.bg-violet {
	background-color: $violet;
}
%bg-white,
.bg-white {
	background-color: $white;
}


// Text Colors

%text-green,
.text-green {
	color: $green;
}
%text-blue,
.text-blue {
	color: $blue;
}
%text-yellow,
.text-yellow {
	color: $yellow;
}
%text-violet,
.text-violet {
	color: $violet;
}
%text-white,
.text-white {
	color: $white;
}