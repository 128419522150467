.news-teaser {
	display: block;
	.inner {
		width: 100%;
		padding-right: 4.5rem;
		@extend %pt-m;
		@extend %pb-m;
		background-size: 32px 32px;
		background-position: calc( 100% - 20px) calc( 100% - 20px);
		background-repeat: no-repeat;
		@include transition-timing;
		@include transition-property(background-position);
		.text-small {
			@extend %pt-xs;
		}
	}
}
.news-teaser:hover .inner {
	background-position: calc( 100% - 10px) calc( 100% - 20px);
}
.mod-news-grid .news-teaser {
	@media (min-width: $grid-breakpoint-xl) {
		width: calc( 100% + 50px );
	}
}

/* WITH card background */

.news-teaser.w-image-card,
.news-teaser.no-image-card {
	color: $blue;
	.inner {
		@extend %pl-m;
		background-image: url('#{$image-base-uri}arrow-blue.svg');
	}
}
.news-teaser.no-image-card {
	display: flex;
	align-items: flex-end;
	height: 100%;
	min-height: 15rem;
}
.news-teaser.w-image-card {
	background-color: $white;
}
.news-teaser.w-image-card:hover .inner,
.news-teaser.no-image-card:hover .inner {
	background-position: calc( 100% - 10px) calc( 100% - 20px);
}

/* withOUT card background */

.news-teaser.w-image {
	color: $white;
	.inner {
		background-image: url('#{$image-base-uri}arrow-white.svg');
	}
}

/* Load more */
a.load-more {
	display: block;
	color: $yellow;
	text-decoration: underline;
	text-align: center;
	@extend %pt-m;
	@extend %pb-m;
}
a.load-more:hover {
	background:rgba(255,255,255,0.05);
	color: $yellow;
}