.container-fluid {
	padding-left: $outside-gutter;
	padding-right: $outside-gutter;
	@media (min-width: $grid-breakpoint-sm) {
		padding-left: $outside-gutter-sm;
		padding-right: $outside-gutter-sm;
	}
	@media (min-width: $grid-breakpoint-md) {
		padding-left: $outside-gutter-md;
		padding-right: $outside-gutter-md;
	}
	@media (min-width: $grid-breakpoint-lg) {
		padding-left: $outside-gutter-lg;
		padding-right: $outside-gutter-lg;
	}
	@media (min-width: $grid-breakpoint-xl) {
		padding-left: $outside-gutter-xl;
		padding-right: $outside-gutter-xl;
	}
	@media (min-width: $grid-breakpoint-xxl) {
		padding-left: $outside-gutter-xxl;
		padding-right: $outside-gutter-xxl;
	}
}
.row {
    margin-right: -10px;
    margin-left: -10px;
}
[class*='col-'] {
	padding-left: 10px;
	padding-right: 10px;
}