[class*=mod-intro] {
	.subline {
		@extend %mt-s;
		max-width: 640px;
	}
}
.mod-intro-full-height,
.mod-intro-w-image {
	.row {
		@media (min-width: $grid-breakpoint-lg) {
			min-height: 100vh;
		}
		.text-col {
			@extend %mt-xl;
			@extend %mb-xl;
			align-self: center;
		}
	}
}
.mod-intro-full-height {
	.row {
		margin-top: 6rem;
		@media (min-width: $grid-breakpoint-lg) {
			margin-top: 0;
		}
	}
}
.mod-intro-w-image {
	.image-col {
		min-height: 440px;
		@media (min-width: $grid-breakpoint-lg) {
			min-height: 100vh;
		}
		.image {
			background-size: cover;
			background-position: center center;
			width: calc( 100% + 40px );
			margin-left: -20px;
			height: 100%;
			@media (min-width: $grid-breakpoint-md) {
				width: calc( 100% + 60px );
				margin-left: -30px;
				min-height: 560px;
			}
			@media (min-width: $grid-breakpoint-lg) {
				width: calc( 100% + 100px );
				margin-left: -50px;
			}
		}
	}
}
.mod-intro-dynamic-height {
	.row {
		margin-top: 8rem;
		@media (min-width: $grid-breakpoint-lg) {
			margin-top: 16rem;
		}
	}
}