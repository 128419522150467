.mod-events {
}

.accordion-header {
	@extend %display-3;
	position: relative;
	@extend %pt-s;
	@extend %pb-s;
	cursor: pointer;
}
.accordion-header:after {
	content: "";
	width: 1rem;
	height: 1rem;
	display: inline-block;
	position: absolute;
	right: 0;
	top: 50%;
	@include transform(translateY(-50%) rotate(0deg));
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	@include transition-timing;
	@include transition-property(transform);
}

.accordion-item.active > .accordion-header:after {
	@include transform(translateY(-50%) rotate(180deg));
}

.accent-green {
	.accordion-header {
	  color: $green;
	}
	.accordion-header:after {
	  background-image: url('#{$image-base-uri}chevron-green.svg');
	}
}
.accent-blue {
	.accordion-header {
	  color: $blue;
	}
	.accordion-header:after {
	  background-image: url('#{$image-base-uri}chevron-blue.svg');
	}
}
.accent-yellow {
	.accordion-header {
	  color: $yellow;
	}
	.accordion-header:after {
	  background-image: url('#{$image-base-uri}chevron-yellow.svg');
	}
}
.accent-violet {
	.accordion-header {
		color: $violet;
	}
	.accordion-header:after {
		background-image: url('#{$image-base-uri}chevron-violet.svg');
	}
}
.accent-white {
	.accordion-header {
	  color: $white;
	}
	.accordion-header:after {
	  background-image: url('#{$image-base-uri}chevron-white.svg');
	}
}

.accordion-body {
  display: none;
}

.event {
	border-bottom: 2px solid rgba(255,255,255,0.5);
	@extend %text-small;
	padding-bottom: 1.875rem;
	margin-bottom: 1.875rem;
	@media (min-width: $grid-breakpoint-sm) {
		display: flex;
		justify-content: space-between;
	}
	.event-description {
		@media (min-width: $grid-breakpoint-sm) {
			width: 60%;
		}
		.event-title {
			@extend %display-3;
			@extend %mb-xs;
		}
	}
	.event-info {
		@media (min-width: $grid-breakpoint-sm) {
			width: 30%;
			max-width: 200px;
		}
		ul {
			@media (max-width: $grid-breakpoint-sm) {
				margin-top: 1.25rem;
			}
			margin-bottom: 0;
			padding: 0;
			list-style-type: none;
			li {
				margin: 10px 0;
				padding-left: 22px;
				background-size: 14px 14px;
				background-repeat: no-repeat;
				background-position: 0px 2px;
			}
			li.date {
				background-image: url('#{$image-base-uri}calendar-white.svg');
			}
			li.location {
				background-image: url('#{$image-base-uri}pin-white.svg');
			}
			li.organizer {
				background-image: url('#{$image-base-uri}person-white.svg');
			}
			li.public {
				background-image: url('#{$image-base-uri}eye-white.svg');
			}
			li.private {
				background-image: url('#{$image-base-uri}eye-stroke-white.svg');
			}
		}
		.btn {
			@extend %mt-s;
		}
	}
}