#logo {
	position: fixed;
	top: 2rem;
	left: 2rem;
	z-index: 700;
	width: 4.5rem;
	height: 4.5rem;
	display: inline-block;
	background-size: contain;
	background-repeat: no-repeat;
	@include transition(background-image);
}
.bg-green #logo,
.bg-blue #logo,
.bg-violet #logo {
	background-image: url('#{$image-base-uri}logo-white.svg');
}
.bg-yellow #logo,
.bg-white #logo {
	background-image: url('#{$image-base-uri}logo-blue.svg');
}



.burger {
	@include transition-custom(background .2s ease);
	background: $white;
	border-radius: 50%;
	position: fixed;
	top: 2.75rem;
	right: 2rem;
	@media (min-width: 800px) {
		right: 3.125rem;
	}
	z-index: 900;
	width: 3rem;
	height: 3rem;
	padding: 1.4375rem 0.6875rem;
	cursor: pointer;
	span {
		display: block;
		width: 1.625rem;
		height: 0.125rem;
		background: $black;
		@include transition-custom(all .2s ease);
	}
	span::before {
		content: "";
		display: block;
		width: 1.625rem;
		height: 0.125rem;
		background: $black;
		position: relative;
		top: -10px;
		@include transition-custom(all .2s ease);
	}
	span::after {
		content: "";
		display: block;
		width: 1.625rem;
		height: 0.125rem;
		background: $black;
		position: relative;
		top: 8px;
		@include transition-custom(all .2s ease);
	}
}
.burger.clicked {
	background: none;
	span {
		background: transparent;
		@include transition-custom(all .2s ease);
	}
	span::before {
		@include transform(translateY(10px) rotate(45deg));
		@include transition-custom(all .2s ease);
		background: $white;
	}
	span::after {
		@include transform(translateY(-10px) rotate(-45deg));
		@include transition-custom(all .2s ease);
		background: $white;
	}
}



nav {
	position: fixed;
	overflow-y: auto;
	z-index: 800;
	top: 0;
	right: calc(-100vw - 72px);
	width: calc(100vw - 72px);
	@media (min-width: 800px) {
		right: -400px;
		width: 400px;
	}
	height: 100%;
	background: $blue;
	@include transition-custom(right .6s cubic-bezier(0.77, 0, 0.175, 1), box-shadow .6s cubic-bezier(0.77, 0, 0.175, 1));
	ul {
		list-style-type: none;
		padding: 0;
		position: relative;
		z-index: 1;
		@media (min-width: 800px) {
			max-width: 360px;
		}
		@media (min-width: $grid-breakpoint-xl) {
			max-width: 300px;
		}
    &.intended {
      margin-left: 20px;
    }
		li {
			margin: 2rem 0;
		}
		a {
			@extend %display-3;
			color: $white;
			display: inline-block;
			@include transition-timing;
			@include transition-property(color);
		}
		a:hover {
			color: $yellow;
		}
		a.has-subnav-link {
      display: flex;

      svg {
        width: 32px;
        height: 32px;
        margin: 0 10px;
        @include transition-timing;
        @include transition-property(transform);
      }

      &:hover svg {
        transform: translateX(10px);
      }
    }
	}
}
nav.show-nav {
	@include box-shadow;
	right: 0;
}

.subnav {
	position: fixed;
	overflow-y: auto;
	top: 0;
	right: calc(-100vw - 72px);
	width: calc(100vw - 72px);
	height: 100%;
	background: $blue;
	@media (min-width: 800px) {
		right: -400px;
		width: 400px;
    background: $grey-blue;
	}
	@include transition-custom(right .6s cubic-bezier(0.77, 0, 0.175, 1));
	li {
		margin: 1.25rem 0;
	}
	a {
		@extend %text-regular;
    font-size: 18px;
	}
	.shortcut {
    a {
      color: $yellow;
    }
	}
  a.has-subnav-link {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}
nav.show-nav .show-subnav > .subnav {
	right: 0;
}

nav, .subnav {
	@extend %pl-l;
	@extend %pr-l;
	@extend %pt-xxl;
	@extend %pb-xl;

	@include media-breakpoint-down(sm) {
		padding-top: 7.5rem;
	}
}

.nav-back-links {
  display: flex;
  position: fixed;
  overflow: hidden;
  right: 0;
  top: 0;
  bottom: 0;
  @include transition-custom(right .5s cubic-bezier(0.77, 0, 0.175, 1), width .6s cubic-bezier(0.77, 0, 0.175, 1));

  nav.show-nav & {
    @include box-shadow;
    right: calc(100vw - 72px);

    @media (min-width: 800px) {
      right: 400px;
    }
  }

  a {
    background: $blue;
    color: $white;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 24px;
    line-height: 32px;
    padding: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    white-space: nowrap;
    writing-mode: vertical-lr;

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.lang-select {
	@extend %display-3;
	color: $white;
	position: fixed;
	padding-top: 2.75rem;
	top: 0;
	line-height: 3rem;
	width: calc(75% - 40px);
  z-index: 1;
	a.active-lang,
	a:hover {
		color: $yellow;
	}
	@include media-breakpoint-up(md) {
		width: calc(75% - 60px);
	}
	@media (min-width: 800px) {
		width: 340px;
	}
	@include media-breakpoint-up(xl) {
		width: 300px;
	}
}






.overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 750;
	width: 100%;
	height: 100%;
	background-color: rgba(3,26,58,0.5);
	visibility: hidden;
	opacity: 0;
	@include transition-custom(all .6s cubic-bezier(0.77, 0, 0.175, 1));
	&.show {
		visibility: visible;
		opacity: 1;
		@include transition-custom(all .6s cubic-bezier(0.77, 0, 0.175, 1));
	}
}

.breadcrumb {
  display: flex;
  list-style: none;
  font-size: 10px;
  line-height: 20px;
  margin: -50px 0 30px;
  padding: 0;

  li {
    display: flex;

    svg {
      height: 11px;
      transform: translateY(-1px);
    }

    span {
      font-weight: bold;
    }

    a {
      text-decoration: underline;
    }

    &:not(:last-child):after {
      content: '/';
      padding: 0 7px;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 14px;
    line-height: 25px;
    left: 130px;
    margin: 0;
    position: fixed;
    top: 52px;
    z-index: 700;

    li svg {
      height: 14px;
    }

    .bg-green &,
    .bg-blue &,
    .bg-violet & {
      color: $white;
    }

    .bg-yellow &,
    .bg-white &,
    .bg-white & {
      color: $blue;
    }
  }
}
