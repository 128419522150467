.mod-stage {
	position: relative;
	@media (min-width: $grid-breakpoint-xl) {
		height: 100vh;
	}
	min-height: 500px;
	@media (min-width: $grid-breakpoint-xl) {
	  	display: -ms-flexbox;
		display: flex;
		min-height: 760px;
	}
	@media (min-width: $grid-breakpoint-xxl) {
		min-height: 920px;
	}

	div.left {
		position: relative;
		height: 85vh;
		min-height: 540px;
		max-height: 700px;
		@media (min-width: $grid-breakpoint-sm) {
			min-height: 660px;
			max-height: 880px;
		}
		@media (min-width: $grid-breakpoint-md) {
			min-height: 820px;
			max-height: none;
		}
		@media (min-width: $grid-breakpoint-lg) {
			height: 100vh;
		}
		@media (min-width: $grid-breakpoint-xl) {
			min-height: 100%;
			height: 100%;
			width: 40%;
		}
		img {
			max-width: 40%;
			@media (min-width: $grid-breakpoint-xxl) {
				max-width: 50%;
			}
			height: auto;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
      &:not(.no-shadow) {
        @include box-shadow;
      }
		}
		.headline {
			@extend %pl-l;
			@extend %pr-l;
			position: absolute;
			max-width: 600px;
			bottom: calc( 40 / 7  * 10vw - 16px);
			@media (min-width: $grid-breakpoint-md) {
				bottom: calc( 40 / 7  * 10vw - 20px);
			}
			@media (min-width: $grid-breakpoint-xl) {
				bottom: calc( 40 * 0.4 / 7  * 10vw - 26px);
			}
			@media (min-width: $grid-breakpoint-xxl) {
				bottom: calc( 20 / 7  * 10vw + 18px);
				@include transform(translateY(50%));
			}
			z-index: 2;
		}
		.subline {
			z-index: 1;
			width: 60%;
			p {
				margin-bottom: 1rem;
				@media (min-width: $grid-breakpoint-md) {
					margin-right: 20px;
					max-width: 400px;
					margin-bottom: 0;
				}
			}
			@media (min-width: $grid-breakpoint-md) {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
			}
			@media (min-width: $grid-breakpoint-xxl) {
				width: 50%;
			}
			position: absolute;
			bottom: 0;
			right: 0;
			@extend %pb-l;
			@extend %pl-l;
			@extend %pr-l;
			@extend %text-small;
		}
	}

	div.right {
		@extend %text-white;
		@media (max-width: $grid-breakpoint-xxl) {
			padding-top: 50%;
		}
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		@extend %pl-l;
		@extend %pr-l;
		@extend %pb-l;
		@extend %pt-xl;
		max-height: 100%;
		@media (min-width: $grid-breakpoint-xl) {
			width: 60%;
		}
		.gradient-overlay {
		    position:absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
			z-index: -1;
			background: -moz-linear-gradient(rgba(0,0,0,0) 65%, rgba(0,0,0,0.5) 100%);
			background: -webkit-linear-gradient(rgba(0,0,0,0) 65%, rgba(0,0,0,0.5) 100%);
			background: linear-gradient(rgba(0,0,0,0) 65%, rgba(0,0,0,0.5) 100%);
			@media (min-width: $grid-breakpoint-xl) {
				background: -moz-linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 65%, rgba(0,0,0,0.5) 100%);
				background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 65%, rgba(0,0,0,0.5) 100%);
				background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 65%, rgba(0,0,0,0.5) 100%);
			}
		}
		.headline {
			z-index: 2;
			@extend %pr-l;
			max-width: 600px;
		}
		.media-wrapper {
		    position:absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    overflow: hidden;
		    z-index: -2;
		    background-color: $blue;
		    background-size: cover;
		    background-position: center center;
			video {
				display: none;
				position: relative;
				top: 0;
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				background-color: $blue;
				/*
				top: 50%;
				left: 50%;
				@include transform(translateY(-50%) translateX(-50%));
	        	height: auto;
	        	min-height: 100%;
	        	min-width: 100%;
				@media (min-width: $grid-breakpoint-xl) {
		        	width: auto;
		        	min-width: 100%;
		        	min-height: 100%;
				}
				*/
				@media (min-width: $grid-breakpoint-md) {
					display: block;
				}
			}
		}
		a.btn-arrow {
			background-image: url('#{$image-base-uri}arrow-white.svg');
			border-color: $white;
		}
		a.btn-arrow:hover {
			background-color: $white;
			background-image: url('#{$image-base-uri}arrow-blue.svg');
		}
	}
}