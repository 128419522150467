.display-0,
%display-0 {
  font-size: 2rem; // 32px
  line-height: 1.2;
  font-weight: 900;
  @media (min-width: $grid-breakpoint-sm) {
    font-size: 2rem; // 32px
  }
  @media (min-width: $grid-breakpoint-md) {
    font-size: 2.625rem; // 42px
  }
  @media (min-width: $grid-breakpoint-lg) {
    font-size: 2.625rem; // 42px
  }
  @media (min-width: $grid-breakpoint-xl) {
    font-size: 4.375rem; // 70px
  }
  @media (min-width: $grid-breakpoint-xxl) {
    font-size: 7.5rem; // 120px
  }
}
.display-1,
%display-1 {
	font-size: 2rem; // 32px
	line-height: 1.2;
	font-weight: 900;
		@media (min-width: $grid-breakpoint-sm) {
			font-size: 2rem; // 32px
		}
		@media (min-width: $grid-breakpoint-md) {
			font-size: 2.625rem; // 42px
		}
		@media (min-width: $grid-breakpoint-lg) {
			font-size: 2.625rem; // 42px
		}
		@media (min-width: $grid-breakpoint-xl) {
			font-size: 3.125rem; // 50px
		}
		@media (min-width: $grid-breakpoint-xxl) {
			font-size: 3.125rem; // 50px
		}
}
.display-2,
%display-2 {
	font-size: 1.75rem; // 28px
	line-height: 1.278;
	font-weight: 900;
		@media (min-width: $grid-breakpoint-sm) {
			font-size: 1.75rem; // 28px
		}
		@media (min-width: $grid-breakpoint-md) {
			font-size: 2rem; // 32px
		}
		@media (min-width: $grid-breakpoint-lg) {
			font-size: 2rem; // 32px
		}
		@media (min-width: $grid-breakpoint-xl) {
			font-size: 2.25rem; // 36px
		}
		@media (min-width: $grid-breakpoint-xxl) {
			font-size: 2.25rem; // 36px
		}
}
.display-3,
%display-3 {
	font-size: 1.375rem; // 22px
	line-height: 1.3;
	font-weight: 700;
		@media (min-width: $grid-breakpoint-sm) {
			font-size: 1.375rem; // 22px
		}
		@media (min-width: $grid-breakpoint-md) {
			font-size: 1.5rem; // 24px
		}
		@media (min-width: $grid-breakpoint-lg) {
			font-size: 1.5rem; // 24px
		}
		@media (min-width: $grid-breakpoint-xl) {
			font-size: 1.5rem; // 24px
		}
		@media (min-width: $grid-breakpoint-xxl) {
			font-size: 1.5rem; // 24px
		}
}

.text-regular,
%text-regular {
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 400;
}
.text-small,
%text-small {
	font-size: .875rem;
	line-height: 1.4;
	font-weight: 400;
}
.text-tiny,
%text-tiny {
	font-size: .625rem;
	line-height: 1.2;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	@extend %mb-xs;
}

i {
	font-family: $font-family-accent;
	font-weight: 900;
}

.text-center,
%text-center {
	text-align: center;
}
.text-left,
%text-left {
	text-align: left;
}
.text-right,
%text-right {
	text-align: right;
}
