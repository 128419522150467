@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/utilities/embed';
@import '~bootstrap/scss/utilities/screenreaders';

@import 'includes/variables';
@import 'includes/fonts';
@import 'includes/mixins';
@import 'includes/typography';
@import 'includes/colors';
@import 'includes/logos';
@import 'includes/bootstrap-overrides';
@import 'includes/spacings';
@import 'includes/aspect-ratio';
@import 'includes/btn';
@import 'includes/rte';
@import 'includes/popup';

@import 'includes/aos-core';
@import 'includes/aos-animations';
@import 'includes/aos-easing';

@import 'includes/navbar';
@import 'includes/footer';
@import 'includes/eye-catcher';
@import 'includes/cookie-consent';

@import 'includes/mod-story-teaser';
@import 'includes/mod-card';
@import 'includes/mod-quicklinks';
@import 'includes/mod-instagram-post';
@import 'includes/mod-news-teaser';
@import 'includes/mod-news-detail';

@import 'includes/mod-stage';
@import 'includes/mod-campaign-stage';
@import 'includes/mod-text';
@import 'includes/mod-statement';
@import 'includes/mod-image-text-teaser';
@import 'includes/mod-intro';
@import 'includes/mod-grid';
@import 'includes/mod-news-grid';
@import 'includes/mod-news-list';
@import 'includes/mod-image';
@import 'includes/mod-quote';
@import 'includes/mod-q-a';
@import 'includes/mod-events';


@at-root {
	@-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	font-family: $font-family;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5;
	color: $blue;
	transition: background-color 1s ease;
	overflow-x: hidden;
}

p {
	margin-bottom: 0;
}

img {
	max-width: 100%;
	height: auto;
}

a, a:hover {
	text-decoration: none;
	color: inherit;
}

.panel {
	@extend %mb-xxl;
}

.read-more {
  &[aria-expanded="true"] {
    margin-bottom: 1.25rem;
  }

  &[data-readmore] {
    display: block;
    width: 100%;
  }

  &[data-readmore] {
    transition: height 100ms;
    overflow: hidden;
  }
}

.spam-protection {
  &--at:before {
    content: '@';
  }

  &--dot:before {
    content: '.';
  }

  &__inner {
    display: inline-block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}
