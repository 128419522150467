.mod-q-a {
	.row:not(:first-child) {
		@extend %mt-l;
	}
	p, img {
		margin-bottom: 1.25rem;
	}
	.question {
		@extend %display-3;
		@media (max-width: $grid-breakpoint-md) {
			margin-top: 2.75rem;
			margin-bottom: 1.25rem;
		}
	}
}
