.card {
	display: flex;
	align-items: flex-end;
	position: relative;
	width: 100%;
	text-decoration: none;
	.inner {
		width: 100%;
		@extend %pt-xl;
		@extend %pb-m;
		@extend %pl-m;
		.subline {
			@extend %mt-xs;
		}
	}
}

a.card {
	.inner {
		padding-right: 72px;
		background-position: calc( 100% - 20px) calc( 100% - 20px);
		background-size: 32px 32px;
		background-repeat: no-repeat;
		@include transition-timing;
		@include transition-property(background-position);
	}
}
a.card.text-green .inner {
	background-image: url('#{$image-base-uri}arrow-green.svg');
}
a.card.text-blue .inner {
	background-image: url('#{$image-base-uri}arrow-blue.svg');
}
a.card.text-yellow .inner {
	background-image: url('#{$image-base-uri}arrow-yellow.svg');
}
a.card.text-violet .inner {
	background-image: url('#{$image-base-uri}arrow-violet.svg');
}
a.card.text-white .inner {
	background-image: url('#{$image-base-uri}arrow-white.svg');
}
a.card:hover .inner {
	text-decoration: none;
	background-position: calc( 100% - 10px) calc( 100% - 20px);
}
a.card.text-green:hover .inner {
	color: $green;
}
a.card.text-blue:hover .inner {
	color: $blue;
}
a.card.text-yellow:hover .inner {
	color: $yellow;
}
a.card.text-violet:hover .inner {
  color: $violet;
}
a.card.text-white:hover .inner {
	color: $white;
}

div.card {
	.inner {
		@extend %pr-m;
	}
}