a.instagram-post {
	display: block;
	position: relative;
	@media (min-width: $grid-breakpoint-xl) {
		width: calc( 100% + 50px );
	}
	img.logo {
		position: absolute;
		bottom: 20px;
		right: 20px;
		@include transition-timing;
		@include transition-property(transform);
	}
}
a.instagram-post:hover {
	img.logo {
		@include transform(scale(1.2));
	}
}

.mod-news-grid a.instagram-post {
	@media (min-width: $grid-breakpoint-xl) {
		width: calc( 100% + 50px );
	}
}

[data-type="placeholder"][data-name="instagram"] {
  @media (min-width: $grid-breakpoint-xl) {
    width: calc( 100% + 50px );
  }
}
