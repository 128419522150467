.newsletter-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: rgba(3,26,58,0.5);
  opacity: 0;
  visibility: hidden;
  @include transition-custom(opacity 0.3s 0s, visibility 0s 0.3s);
}
.newsletter-popup.is-visible {
  opacity: 1;
  visibility: visible;
  @include transition-custom(opacity 0.3s 0s, visibility 0s 0s);
}
.newsletter-popup-container {
  position: relative;
  width: 90%;
  max-width: 420px;
  @extend %pt-l;
  @extend %pb-l;
  @extend %pl-l;
  @extend %pr-l;
  margin: 9rem auto;
  background-color: $green;
  text-align: center;
  @include transform(translateY(-40px));
  @include transition(transform);
  /* Force Hardware Acceleration in WebKit */
  -webkit-backface-visibility: hidden;
  .display-1 {
    @extend %mb-s;
  }
  .btn {
    @extend %mt-m;
  }
}
.is-visible .newsletter-popup-container {
  @include transform(translateY(0));
}