
@mixin box-shadow($property) {
	-webkit-box-shadow:	$property;
	-moz-box-shadow:	$property;
	box-shadow:			$property;
}
@mixin box-shadow {
	-webkit-box-shadow:	5px 5px 45px 0px rgba(0,0,0,0.30);
	-moz-box-shadow:	5px 5px 45px 0px rgba(0,0,0,0.30);
	box-shadow:			5px 5px 45px 0px rgba(0,0,0,0.30);
}
@mixin box-shadow-bottom {
	-webkit-box-shadow:	5px 25px 25px 0px rgba(0,0,0,0.30);
	-moz-box-shadow:	5px 25px 25px 0px rgba(0,0,0,0.30);
	box-shadow:			5px 25px 25px 0px rgba(0,0,0,0.30);
}



@mixin border-radius($property) {
	border-radius:			$property;
	-moz-border-radius:		$property;
	-webkit-border-radius:	$property;
}



@mixin transition($property) { // delete this one
	-webkit-transition:	$property 1.3s ease;
	-moz-transition:	$property 1.3s ease;
	-o-transition:		$property 1.3s ease;
	transition:			$property 1.3s ease;
}
@mixin transition-timing {
	-webkit-transition-duration:		.3s;
	-moz-transition-duration:			.3s;
	-o-transition-duration:				.3s;
	transition-duration:				.3s;
	-webkit-transition-timing-function:	ease;
	-moz-transition-timing-function:	ease;
	-o-transition-timing-function:		ease;
	transition-timing-function:			ease;
}
@mixin transition-property($property...) {
	-webkit-transition-property:	$property;
	-moz-transition-property:		$property;
	-o-transition-property:			$property;
	transition-property:			$property;
}
@mixin transition-custom($property...) {
	-webkit-transition:	$property;
	-moz-transition:	$property;
	-o-transition:		$property;
	transition:			$property;
}



@mixin transform($property) {
	-webkit-transform:	$property;
	-moz-transform:		$property;
	-ms-transform:		$property;
	-o-transform:		$property;
	transform:			$property;
}