.mod-news-list {
	[class*=col] {
		margin-bottom: 3.75rem;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		@media (min-width: $grid-breakpoint-md) {
			padding: 0 1.25rem;
		}
		@media (min-width: $grid-breakpoint-xl) {
			padding: 0 2.5rem;
		}
	}
	[class*=col] > :not(:first-child) {
		@extend %mt-s;
	}

}