.mod-grid {
	[class*=col] {
		@extend %mb-s;
		display: flex;
		flex-direction: column;
	}
	[class*=col] > :not(:first-child) {
		@extend %mt-s;
	}
}
.mod-grid.align-center .row [class*=col] {
	justify-content: center;
}