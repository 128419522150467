.eye-catcher {
  max-width: 30vw;
  position: fixed;
  top: 2rem;
  right: 6rem;
  z-index: 1;
  @media (min-width: 800px) {
    right: 8.125rem;
  }
}
