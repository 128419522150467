@font-face {
  font-family: Chivo;
  src: url('#{$font-base-uri}chivo-regular.woff2') format('woff2'),
       url('#{$font-base-uri}chivo-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Chivo;
  src: url('#{$font-base-uri}chivo-bold.woff2') format('woff2'),
       url('#{$font-base-uri}chivo-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Chivo;
  src: url('#{$font-base-uri}chivo-black.woff2') format('woff2'),
       url('#{$font-base-uri}chivo-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}


@font-face {
	font-family: 'Swear Display';
	src: url('#{$font-base-uri}swear-display-black-italic.woff2') format('woff2'),
			 url('#{$font-base-uri}swear-display-black-italic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}
