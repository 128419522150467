.story-teaser {
	display: flex;
	align-items: flex-end;
	position: relative;
	background-size: cover;
	background-position: center center;
	text-decoration: none;
  &:not(.no-shadow) {
    @include box-shadow;
  }
	.text-overlay {
		width: 100%;
		background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
		background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
		color: white;
		.inner {
			width: 100%;
			padding-right: 72px;
			@extend %pt-xl;
			@extend %pb-m;
			@extend %pl-m;
			.text-small {
				@extend %mt-xs;
			}
			background-position: calc( 100% - 20px) calc( 100% - 20px);
			background-size: 32px 32px;
			background-repeat: no-repeat;
			background-image: url('#{$image-base-uri}arrow-white.svg');
			@include transition-timing;
			@include transition-property(background-position);
		}
	}
}
.story-teaser:hover {
	.inner {
		background-position: calc( 100% - 10px) calc( 100% - 20px);
	}
}