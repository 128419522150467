.mod-campaign-stage {
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }

  .stickers {
    img {
      position: absolute;
      max-width: 200px;

      &:nth-child(1) {
        left: 20%;
        top: 20%;
      }

      &:nth-child(2) {
        right: 10%;
        top: 25%;
      }

      &:nth-child(3) {
        left: 10%;
        bottom: 15%;
      }

      &:nth-child(4) {
        right: 8%;
        bottom: 20%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .stickers {
      img {
        max-width: 120px;

        &:nth-child(1) {
          left: 5%;
        }

        &:nth-child(2) {
          right: 2%;
        }

        &:nth-child(3) {
          left: 2%;
        }

        &:nth-child(4) {
          right: 8%;
        }
      }
    }
  }
}
