.mod-news-grid {
	.row {
		align-items: center;
	}
	[class*=col] {
		@extend %mb-s;
		display: flex;
		flex-direction: column;
	}
	[class*=col] > :not(:first-child) {
		@extend %mt-s;
	}
	[class*=col].text-col {
		@extend %mt-xl;
		@extend %mb-xl;
	}
}