.aspect-ratio-10-7::before {
  content: "";
  float: left;
  padding-bottom: calc( 100 / 10 * 7%);
}
.aspect-ratio-10-7::after {
  clear: left;
  content: "";
  display: table;
  width: 0;
}


.aspect-ratio-7-10::before {
  content: "";
  float: left;
  padding-bottom: calc( 100 / 7 * 10%);
}
.aspect-ratio-7-10::after {
  clear: left;
  content: "";
  display: table;
  width: 0;
}


.aspect-ratio-1-1::before {
  content: "";
  float: left;
  padding-bottom: 100%;
}
.aspect-ratio-1-1::after {
  clear: left;
  content: "";
  display: table;
  width: 0;
}


.aspect-ratio-stretch {
  flex-grow: 1;
}