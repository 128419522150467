ul.quicklinks {
	list-style-type: none;
	margin-bottom: 0;
	padding: 1rem 0 0 0;
	li {
		@extend %mb-s;
		.btn {
			width: 100%;
		}
	}
}