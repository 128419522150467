.mod-news-detail {
	.row {
		margin-top: 8rem;
		@media (min-width: $grid-breakpoint-lg) {
			margin-top: 16rem;
		}
		.intro {
			@extend %mb-xl;
			.date {
				@extend %mt-s;
				max-width: 640px;
			}
		}
	}
}