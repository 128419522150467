/*!
 * Bootstrap Reboot v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*!
 * Bootstrap Grid v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item, .embed-responsive [data-type=placeholder],
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

@font-face {
  font-family: Chivo;
  src: url("../Fonts/chivo-regular.woff2") format("woff2"), url("../Fonts/chivo-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Chivo;
  src: url("../Fonts/chivo-bold.woff2") format("woff2"), url("../Fonts/chivo-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Chivo;
  src: url("../Fonts/chivo-black.woff2") format("woff2"), url("../Fonts/chivo-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Swear Display";
  src: url("../Fonts/swear-display-black-italic.woff2") format("woff2"), url("../Fonts/swear-display-black-italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
.display-0 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 900;
}
@media (min-width: 576px) {
  .display-0 {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .display-0 {
    font-size: 2.625rem;
  }
}
@media (min-width: 992px) {
  .display-0 {
    font-size: 2.625rem;
  }
}
@media (min-width: 1200px) {
  .display-0 {
    font-size: 4.375rem;
  }
}
@media (min-width: 1400px) {
  .display-0 {
    font-size: 7.5rem;
  }
}

.display-1 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 900;
}
@media (min-width: 576px) {
  .display-1 {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .display-1 {
    font-size: 2.625rem;
  }
}
@media (min-width: 992px) {
  .display-1 {
    font-size: 2.625rem;
  }
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 3.125rem;
  }
}
@media (min-width: 1400px) {
  .display-1 {
    font-size: 3.125rem;
  }
}

.display-2 {
  font-size: 1.75rem;
  line-height: 1.278;
  font-weight: 900;
}
@media (min-width: 576px) {
  .display-2 {
    font-size: 1.75rem;
  }
}
@media (min-width: 768px) {
  .display-2 {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .display-2 {
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 2.25rem;
  }
}
@media (min-width: 1400px) {
  .display-2 {
    font-size: 2.25rem;
  }
}

.display-3,
.event .event-description .event-title,
.accordion-header,
.mod-q-a .question,
.lang-select,
nav ul a {
  font-size: 1.375rem;
  line-height: 1.3;
  font-weight: 700;
}
@media (min-width: 576px) {
  .display-3,
.event .event-description .event-title,
.accordion-header,
.mod-q-a .question,
.lang-select,
nav ul a {
    font-size: 1.375rem;
  }
}
@media (min-width: 768px) {
  .display-3,
.event .event-description .event-title,
.accordion-header,
.mod-q-a .question,
.lang-select,
nav ul a {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .display-3,
.event .event-description .event-title,
.accordion-header,
.mod-q-a .question,
.lang-select,
nav ul a {
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .display-3,
.event .event-description .event-title,
.accordion-header,
.mod-q-a .question,
.lang-select,
nav ul a {
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .display-3,
.event .event-description .event-title,
.accordion-header,
.mod-q-a .question,
.lang-select,
nav ul a {
    font-size: 1.5rem;
  }
}

.text-regular,
.subnav a {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.text-small,
.event,
.mod-image .image-caption,
.mod-statement .sidenote,
.mod-stage div.left .subline,
footer label,
footer ul.legal li a {
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.text-tiny {
  font-size: 0.625rem;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

i {
  font-family: "Swear Display", Georgia, cursive;
  font-weight: 900;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bg-green {
  background-color: #00b8a1;
}

.bg-blue {
  background-color: #031a3a;
}

.bg-yellow {
  background-color: #faff8e;
}

.bg-violet {
  background-color: #8c30ff;
}

.bg-white {
  background-color: #fff;
}

.text-green {
  color: #00b8a1;
}

.text-blue {
  color: #031a3a;
}

.text-yellow {
  color: #faff8e;
}

.text-violet {
  color: #8c30ff;
}

.mod-stage div.right,
.text-white {
  color: #fff;
}

/*
%logo {
	font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    -webkit-background-size: contain;
    background-size: contain;
    display: inline-block;
    width: 11.75rem;
    height: 2.5rem;
}

%logo-light,
.logo-light {
    @extend %logo;
    background: url(../Images/logo-landscape-white.svg) no-repeat;
}
%logo-dark,
.logo-dark {
    @extend %logo;
    background: url(../Images/logo-landscape-blue.svg) no-repeat;
}
*/
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media (min-width: 576px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media (min-width: 768px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
@media (min-width: 992px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}
@media (min-width: 1400px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

[class*=col-] {
  padding-left: 10px;
  padding-right: 10px;
}

/* -- MARGINS ----------------------------------------------------------- */
.mt-xxl {
  margin-top: 5rem;
}

.mt-xl, .mod-news-grid [class*=col].text-col, .mod-intro-full-height .row .text-col,
.mod-intro-w-image .row .text-col, footer .newsletter-sub, .rte img.large {
  margin-top: 3.75rem;
}

.mt-l, .mod-q-a .row:not(:first-child) {
  margin-top: 1.25rem;
}

.mt-m, .mod-statement div.btn-wrapper, .mod-statement .sidenote, .newsletter-popup-container .btn {
  margin-top: 1.24rem;
}

.mt-s, .event .event-info .btn, .mod-quote .author, .mod-news-list [class*=col] > :not(:first-child), .mod-news-grid [class*=col] > :not(:first-child), .mod-grid [class*=col] > :not(:first-child), [class*=mod-intro] .subline, .mod-news-detail .row .intro .date {
  margin-top: 1rem;
}

.mt-xs, .mod-image .image-caption, .card .inner .subline, .story-teaser .text-overlay .inner .text-small, footer .text-small {
  margin-top: 0.625rem;
}

.mb-xxl, .panel {
  margin-bottom: 5rem;
}

.mb-xl, .mod-news-grid [class*=col].text-col, .mod-intro-full-height .row .text-col,
.mod-intro-w-image .row .text-col, .mod-news-detail .row .intro, footer .newsletter-sub, .rte img.large {
  margin-bottom: 3.75rem;
}

.mb-l, footer ul.legal, footer ul.social {
  margin-bottom: 1.25rem;
}

.mb-m {
  margin-bottom: 1.24rem;
}

.mb-s, .mod-news-grid [class*=col], .mod-grid [class*=col], .mod-image-text-teaser .text-col .headline, ul.quicklinks li, .newsletter-popup-container .display-1 {
  margin-bottom: 1rem;
}

.mb-xs, .event .event-description .event-title, .text-tiny {
  margin-bottom: 0.625rem;
}

.ml-xxl {
  margin-left: 5rem;
}

.ml-xl {
  margin-left: 3.75rem;
}

.ml-l {
  margin-left: 1.25rem;
}

.ml-m {
  margin-left: 1.24rem;
}

.ml-s {
  margin-left: 1rem;
}

.ml-xs {
  margin-left: 0.625rem;
}

.mr-xxl {
  margin-right: 5rem;
}

.mr-xl {
  margin-right: 3.75rem;
}

.mr-l {
  margin-right: 1.25rem;
}

.mr-m {
  margin-right: 1.24rem;
}

.mr-s {
  margin-right: 1rem;
}

.mr-xs {
  margin-right: 0.625rem;
}

@media (min-width: 768px) {
  .mt-xxl {
    margin-top: 7.5rem;
  }
  .mt-xl, .mod-news-grid [class*=col].text-col, .mod-intro-full-height .row .text-col,
.mod-intro-w-image .row .text-col, footer .newsletter-sub, .rte img.large {
    margin-top: 5rem;
  }
  .mt-l, .mod-q-a .row:not(:first-child) {
    margin-top: 1.875rem;
  }
  .mt-m, .mod-statement div.btn-wrapper, .mod-statement .sidenote, .newsletter-popup-container .btn {
    margin-top: 1.875rem;
  }
  .mt-s, .event .event-info .btn, .mod-quote .author, .mod-news-list [class*=col] > :not(:first-child), .mod-news-grid [class*=col] > :not(:first-child), .mod-grid [class*=col] > :not(:first-child), [class*=mod-intro] .subline, .mod-news-detail .row .intro .date {
    margin-top: 1.25rem;
  }
  .mt-xs, .mod-image .image-caption, .card .inner .subline, .story-teaser .text-overlay .inner .text-small, footer .text-small {
    margin-top: 0.625rem;
  }
  .mb-xxl, .panel {
    margin-bottom: 7.5rem;
  }
  .mb-xl, .mod-news-grid [class*=col].text-col, .mod-intro-full-height .row .text-col,
.mod-intro-w-image .row .text-col, .mod-news-detail .row .intro, footer .newsletter-sub, .rte img.large {
    margin-bottom: 5rem;
  }
  .mb-l, footer ul.legal, footer ul.social {
    margin-bottom: 1.875rem;
  }
  .mb-m {
    margin-bottom: 1.875rem;
  }
  .mb-s, .mod-news-grid [class*=col], .mod-grid [class*=col], .mod-image-text-teaser .text-col .headline, ul.quicklinks li, .newsletter-popup-container .display-1 {
    margin-bottom: 1.25rem;
  }
  .mb-xs, .event .event-description .event-title, .text-tiny {
    margin-bottom: 0.625rem;
  }
  .ml-xxl {
    margin-left: 7.5rem;
  }
  .ml-xl {
    margin-left: 5rem;
  }
  .ml-l {
    margin-left: 1.875rem;
  }
  .ml-m {
    margin-left: 1.875rem;
  }
  .ml-s {
    margin-left: 1.25rem;
  }
  .ml-xs {
    margin-left: 0.625rem;
  }
  .mr-xxl {
    margin-right: 7.5rem;
  }
  .mr-xl {
    margin-right: 5rem;
  }
  .mr-l {
    margin-right: 1.875rem;
  }
  .mr-m {
    margin-right: 1.875rem;
  }
  .mr-s {
    margin-right: 1.25rem;
  }
  .mr-xs {
    margin-right: 0.625rem;
  }
}
@media (min-width: 1200px) {
  .mt-xxl {
    margin-top: 12.5rem;
  }
  .mt-xl, .mod-news-grid [class*=col].text-col, .mod-intro-full-height .row .text-col,
.mod-intro-w-image .row .text-col, footer .newsletter-sub, .rte img.large {
    margin-top: 7.5rem;
  }
  .mt-l, .mod-q-a .row:not(:first-child) {
    margin-top: 3.125rem;
  }
  .mt-m, .mod-statement div.btn-wrapper, .mod-statement .sidenote, .newsletter-popup-container .btn {
    margin-top: 1.875rem;
  }
  .mt-s, .event .event-info .btn, .mod-quote .author, .mod-news-list [class*=col] > :not(:first-child), .mod-news-grid [class*=col] > :not(:first-child), .mod-grid [class*=col] > :not(:first-child), [class*=mod-intro] .subline, .mod-news-detail .row .intro .date {
    margin-top: 1.25rem;
  }
  .mt-xs, .mod-image .image-caption, .card .inner .subline, .story-teaser .text-overlay .inner .text-small, footer .text-small {
    margin-top: 0.625rem;
  }
  .mb-xxl, .panel {
    margin-bottom: 12.5rem;
  }
  .mb-xl, .mod-news-grid [class*=col].text-col, .mod-intro-full-height .row .text-col,
.mod-intro-w-image .row .text-col, .mod-news-detail .row .intro, footer .newsletter-sub, .rte img.large {
    margin-bottom: 7.5rem;
  }
  .mb-l, footer ul.legal, footer ul.social {
    margin-bottom: 3.125rem;
  }
  .mb-m {
    margin-bottom: 1.875rem;
  }
  .mb-s, .mod-news-grid [class*=col], .mod-grid [class*=col], .mod-image-text-teaser .text-col .headline, ul.quicklinks li, .newsletter-popup-container .display-1 {
    margin-bottom: 1.25rem;
  }
  .mb-xs, .event .event-description .event-title, .text-tiny {
    margin-bottom: 0.625rem;
  }
  .ml-xxl {
    margin-left: 12.5rem;
  }
  .ml-xl {
    margin-left: 7.5rem;
  }
  .ml-l {
    margin-left: 3.125rem;
  }
  .ml-m {
    margin-left: 1.875rem;
  }
  .ml-s {
    margin-left: 1.25rem;
  }
  .ml-xs {
    margin-left: 0.625rem;
  }
  .mr-xxl {
    margin-right: 12.5rem;
  }
  .mr-xl {
    margin-right: 7.5rem;
  }
  .mr-l {
    margin-right: 3.125rem;
  }
  .mr-m {
    margin-right: 1.875rem;
  }
  .mr-s {
    margin-right: 1.25rem;
  }
  .mr-xs {
    margin-right: 0.625rem;
  }
}
/* -- PADDINGS ----------------------------------------------------------- */
.pt-xxl, nav, .subnav {
  padding-top: 5rem;
}

.pt-xl, .mod-image-text-teaser .text-col, .mod-stage div.right, .card .inner, .story-teaser .text-overlay .inner {
  padding-top: 3.75rem;
}

.pt-l, .newsletter-popup-container {
  padding-top: 1.25rem;
}

.pt-m, a.load-more, .news-teaser .inner {
  padding-top: 1.24rem;
}

.pt-s, .accordion-header {
  padding-top: 1rem;
}

.pt-xs, .news-teaser .inner .text-small {
  padding-top: 0.625rem;
}

.pb-xxl {
  padding-bottom: 5rem;
}

.pb-xl, .mod-image-text-teaser .text-col, nav, .subnav {
  padding-bottom: 3.75rem;
}

.pb-l, .mod-stage div.right, .mod-stage div.left .subline, .newsletter-popup-container {
  padding-bottom: 1.25rem;
}

.pb-m, a.load-more, .news-teaser .inner, .card .inner, .story-teaser .text-overlay .inner {
  padding-bottom: 1.24rem;
}

.pb-s, .accordion-header {
  padding-bottom: 1rem;
}

.pb-xs {
  padding-bottom: 0.625rem;
}

.pl-xxl {
  padding-left: 5rem;
}

.pl-xl {
  padding-left: 3.75rem;
}

.pl-l, .mod-stage div.right, .mod-stage div.left .subline, .mod-stage div.left .headline, nav, .subnav, .newsletter-popup-container {
  padding-left: 1.25rem;
}

.pl-m, .news-teaser.w-image-card .inner,
.news-teaser.no-image-card .inner, .card .inner, .story-teaser .text-overlay .inner {
  padding-left: 1.24rem;
}

.pl-s {
  padding-left: 1rem;
}

.pl-xs {
  padding-left: 0.625rem;
}

.pr-xxl {
  padding-right: 5rem;
}

.pr-xl {
  padding-right: 3.75rem;
}

.pr-l, .mod-stage div.right .headline, .mod-stage div.right, .mod-stage div.left .subline, .mod-stage div.left .headline, nav, .subnav, .newsletter-popup-container {
  padding-right: 1.25rem;
}

.pr-m, div.card .inner {
  padding-right: 1.24rem;
}

.pr-s {
  padding-right: 1rem;
}

.pr-xs {
  padding-right: 0.625rem;
}

@media (min-width: 768px) {
  .pt-xxl, nav, .subnav {
    padding-top: 7.5rem;
  }
  .pt-xl, .mod-image-text-teaser .text-col, .mod-stage div.right, .card .inner, .story-teaser .text-overlay .inner {
    padding-top: 5rem;
  }
  .pt-l, .newsletter-popup-container {
    padding-top: 1.875rem;
  }
  .pt-m, a.load-more, .news-teaser .inner {
    padding-top: 1.875rem;
  }
  .pt-s, .accordion-header {
    padding-top: 1.25rem;
  }
  .pt-xs, .news-teaser .inner .text-small {
    padding-top: 0.625rem;
  }
  .pb-xxl {
    padding-bottom: 7.5rem;
  }
  .pb-xl, .mod-image-text-teaser .text-col, nav, .subnav {
    padding-bottom: 5rem;
  }
  .pb-l, .mod-stage div.right, .mod-stage div.left .subline, .newsletter-popup-container {
    padding-bottom: 1.875rem;
  }
  .pb-m, a.load-more, .news-teaser .inner, .card .inner, .story-teaser .text-overlay .inner {
    padding-bottom: 1.875rem;
  }
  .pb-s, .accordion-header {
    padding-bottom: 1.25rem;
  }
  .pb-xs {
    padding-bottom: 0.625rem;
  }
  .pl-xxl {
    padding-left: 7.5rem;
  }
  .pl-xl {
    padding-left: 5rem;
  }
  .pl-l, .mod-stage div.right, .mod-stage div.left .subline, .mod-stage div.left .headline, nav, .subnav, .newsletter-popup-container {
    padding-left: 1.875rem;
  }
  .pl-m, .news-teaser.w-image-card .inner,
.news-teaser.no-image-card .inner, .card .inner, .story-teaser .text-overlay .inner {
    padding-left: 1.875rem;
  }
  .pl-s {
    padding-left: 1.25rem;
  }
  .pl-xs {
    padding-left: 0.625rem;
  }
  .pr-xxl {
    padding-right: 7.5rem;
  }
  .pr-xl {
    padding-right: 5rem;
  }
  .pr-l, .mod-stage div.right .headline, .mod-stage div.right, .mod-stage div.left .subline, .mod-stage div.left .headline, nav, .subnav, .newsletter-popup-container {
    padding-right: 1.875rem;
  }
  .pr-m, div.card .inner {
    padding-right: 1.875rem;
  }
  .pr-s {
    padding-right: 1.25rem;
  }
  .pr-xs {
    padding-right: 0.625rem;
  }
}
@media (min-width: 1200px) {
  .pt-xxl, nav, .subnav {
    padding-top: 12.5rem;
  }
  .pt-xl, .mod-image-text-teaser .text-col, .mod-stage div.right, .card .inner, .story-teaser .text-overlay .inner {
    padding-top: 7.5rem;
  }
  .pt-l, .newsletter-popup-container {
    padding-top: 3.125rem;
  }
  .pt-m, a.load-more, .news-teaser .inner {
    padding-top: 1.875rem;
  }
  .pt-s, .accordion-header {
    padding-top: 1.25rem;
  }
  .pt-xs, .news-teaser .inner .text-small {
    padding-top: 0.625rem;
  }
  .pb-xxl {
    padding-bottom: 12.5rem;
  }
  .pb-xl, .mod-image-text-teaser .text-col, nav, .subnav {
    padding-bottom: 7.5rem;
  }
  .pb-l, .mod-stage div.right, .mod-stage div.left .subline, .newsletter-popup-container {
    padding-bottom: 3.125rem;
  }
  .pb-m, a.load-more, .news-teaser .inner, .card .inner, .story-teaser .text-overlay .inner {
    padding-bottom: 1.875rem;
  }
  .pb-s, .accordion-header {
    padding-bottom: 1.25rem;
  }
  .pb-xs {
    padding-bottom: 0.625rem;
  }
  .pl-xxl {
    padding-left: 12.5rem;
  }
  .pl-xl {
    padding-left: 7.5rem;
  }
  .pl-l, .mod-stage div.right, .mod-stage div.left .subline, .mod-stage div.left .headline, nav, .subnav, .newsletter-popup-container {
    padding-left: 3.125rem;
  }
  .pl-m, .news-teaser.w-image-card .inner,
.news-teaser.no-image-card .inner, .card .inner, .story-teaser .text-overlay .inner {
    padding-left: 1.875rem;
  }
  .pl-s {
    padding-left: 1.25rem;
  }
  .pl-xs {
    padding-left: 0.625rem;
  }
  .pr-xxl {
    padding-right: 12.5rem;
  }
  .pr-xl {
    padding-right: 7.5rem;
  }
  .pr-l, .mod-stage div.right .headline, .mod-stage div.right, .mod-stage div.left .subline, .mod-stage div.left .headline, nav, .subnav, .newsletter-popup-container {
    padding-right: 3.125rem;
  }
  .pr-m, div.card .inner {
    padding-right: 1.875rem;
  }
  .pr-s {
    padding-right: 1.25rem;
  }
  .pr-xs {
    padding-right: 0.625rem;
  }
}
.aspect-ratio-10-7::before {
  content: "";
  float: left;
  padding-bottom: 70%;
}

.aspect-ratio-10-7::after {
  clear: left;
  content: "";
  display: table;
  width: 0;
}

.aspect-ratio-7-10::before {
  content: "";
  float: left;
  padding-bottom: 142.8571428571%;
}

.aspect-ratio-7-10::after {
  clear: left;
  content: "";
  display: table;
  width: 0;
}

.aspect-ratio-1-1::before {
  content: "";
  float: left;
  padding-bottom: 100%;
}

.aspect-ratio-1-1::after {
  clear: left;
  content: "";
  display: table;
  width: 0;
}

.aspect-ratio-stretch {
  flex-grow: 1;
}

.btn {
  display: inline-block;
  background: #fff;
  border-radius: 46px;
  -moz-border-radius: 46px;
  -webkit-border-radius: 46px;
  -webkit-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  padding: 11px 72px 11px 30px;
  font-weight: 700;
  text-decoration: none;
  background-position: calc(100% - 20px) center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: background-position;
  -moz-transition-property: background-position;
  -o-transition-property: background-position;
  transition-property: background-position;
}

a.btn:hover {
  text-decoration: none;
  background-position: calc(100% - 10px) center;
}

[data-color=green] .btn,
.bg-green .btn {
  color: #00b8a1;
}
[data-color=green] a.btn,
.bg-green a.btn {
  background-image: url("../Images/arrow-green.svg");
}

[data-color=blue] .btn,
.bg-blue .btn {
  color: #031a3a;
}
[data-color=blue] a.btn,
.bg-blue a.btn {
  background-image: url("../Images/arrow-blue.svg");
}

[data-color=yellow] .btn,
.bg-yellow .btn {
  color: #031a3a;
}
[data-color=yellow] a.btn,
.bg-yellow a.btn {
  background-image: url("../Images/arrow-blue.svg");
}

[data-color=violet] .btn,
.bg-violet .btn {
  color: #8c30ff;
}
[data-color=violet] a.btn,
.bg-violet a.btn {
  background-image: url("../Images/arrow-violet.svg");
}

[data-color=white] .btn,
.bg-white .btn {
  color: #00b8a1;
}
[data-color=white] a.btn,
.bg-white a.btn {
  background-image: url("../Images/arrow-green.svg");
}

.btn-arrow {
  display: inline-block;
  width: 46px;
  height: 46px;
  border-radius: 46px;
  -moz-border-radius: 46px;
  -webkit-border-radius: 46px;
  border: 2px solid #fff;
  background-image: url("../Images/arrow-white.svg");
  background-size: 46px 46px;
  background-position: -2px -2px;
  flex-shrink: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: transform, background-color;
  -moz-transition-property: transform, background-color;
  -o-transition-property: transform, background-color;
  transition-property: transform, background-color;
}

a.btn-arrow:hover {
  background-color: #fff;
  background-image: url("../Images/arrow-blue.svg");
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.text-green .btn-arrow {
  background-image: url("../Images/arrow-green.svg");
  border-color: #00b8a1;
}
.text-green a.btn-arrow:hover {
  background-color: #00b8a1;
}

.text-blue .btn-arrow {
  background-image: url("../Images/arrow-blue.svg");
  border-color: #031a3a;
}
.text-blue a.btn-arrow:hover {
  background-color: #031a3a;
}

.text-yellow .btn-arrow {
  background-image: url("../Images/arrow-yellow.svg");
  border-color: #faff8e;
}
.text-yellow a.btn-arrow:hover {
  background-color: #faff8e;
}

.text-violet .btn-arrow {
  background-image: url("../Images/arrow-violet.svg");
  border-color: #8c30ff;
}
.text-violet a.btn-arrow:hover {
  background-color: #8c30ff;
}

.text-white .btn-arrow {
  background-image: url("../Images/arrow-white.svg");
  border-color: #fff;
}
.text-white a.btn-arrow:hover {
  background-color: #fff;
}

[data-color=green] a.btn-arrow:hover,
.bg-green a.btn-arrow:hover {
  background-image: url("../Images/arrow-green.svg");
}

[data-color=blue] a.btn-arrow:hover,
.bg-blue a.btn-arrow:hover {
  background-image: url("../Images/arrow-blue.svg");
}

[data-color=yellow] a.btn-arrow:hover,
.bg-yellow a.btn-arrow:hover {
  background-image: url("../Images/arrow-yellow.svg");
}

[data-color=violet] a.btn-arrow:hover,
.bg-violet a.btn-arrow:hover {
  background-image: url("../Images/arrow-violet.svg");
}

[data-color=white] a.btn-arrow:hover,
.bg-white a.btn-arrow:hover {
  background-image: url("../Images/arrow-white.svg");
}

.rte p, .rte img {
  margin-bottom: 1.25rem;
}
.rte img.large {
  -webkit-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
}
@media (min-width: 768px) {
  .rte img.large {
    max-width: calc(100% + 34vw);
    margin-left: -17vw;
  }
}
@media (min-width: 768px) {
  .rte img.large {
    max-width: calc(100% + 34vw);
    margin-left: -17vw;
  }
}
@media (min-width: 992px) {
  .rte img.large {
    max-width: calc(100% + 18vw);
    margin-left: -9vw;
  }
}
@media (min-width: 1200px) {
  .rte img.large {
    max-width: calc(100% + 33vw);
    margin-left: -16.5vw;
  }
}
.rte [class*=display-]:not(:first-child) {
  margin-top: 2.75rem;
}
.rte p:last-child {
  margin-bottom: 0;
}
.rte a {
  text-decoration: underline;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: color, background-color;
  -moz-transition-property: color, background-color;
  -o-transition-property: color, background-color;
  transition-property: color, background-color;
}
.rte a:hover {
  text-decoration: none;
}
.rte p:not(.text-tiny),
.rte .rte img {
  margin-bottom: 1.25rem;
}
.rte .text-tiny + [class*=display-] {
  margin-top: 0 !important;
}

.text-green .rte a {
  color: #00b8a1;
}
.text-green .rte a:hover {
  background-color: #00b8a1;
}

.text-blue .rte a {
  color: #031a3a;
}
.text-blue .rte a:hover {
  background-color: #031a3a;
}

.text-yellow .rte a {
  color: #faff8e;
}
.text-yellow .rte a:hover {
  background-color: #faff8e;
}

.text-violet .rte a {
  color: #8c30ff;
}
.text-violet .rte a:hover {
  background-color: #8c30ff;
}

.text-white .rte a {
  color: #fff;
}
.text-white .rte a:hover {
  background-color: #fff;
}

[data-color=green] .rte a:hover {
  color: #00b8a1;
}

[data-color=blue] .rte a:hover {
  color: #031a3a;
}

[data-color=yellow] .rte a:hover {
  color: #faff8e;
}

[data-color=white] .rte a:hover {
  color: #fff;
}

.newsletter-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: rgba(3, 26, 58, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -o-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.newsletter-popup.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  -o-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.newsletter-popup-container {
  position: relative;
  width: 90%;
  max-width: 420px;
  margin: 9rem auto;
  background-color: #00b8a1;
  text-align: center;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-transition: transform 1.3s ease;
  -moz-transition: transform 1.3s ease;
  -o-transition: transform 1.3s ease;
  transition: transform 1.3s ease;
  /* Force Hardware Acceleration in WebKit */
  -webkit-backface-visibility: hidden;
}
.is-visible .newsletter-popup-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}
body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0;
}
body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}
body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: 100ms;
}
body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0;
}
body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: 100ms;
}
body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: 150ms;
}
body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0;
}
body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: 150ms;
}
body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: 200ms;
}
body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0;
}
body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: 200ms;
}
body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: 250ms;
}
body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0;
}
body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: 250ms;
}
body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: 300ms;
}
body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0;
}
body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: 300ms;
}
body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: 350ms;
}
body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0;
}
body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: 350ms;
}
body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: 400ms;
}
body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0;
}
body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: 400ms;
}
body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: 450ms;
}
body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0;
}
body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: 450ms;
}
body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: 500ms;
}
body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0;
}
body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 500ms;
}
body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: 550ms;
}
body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0;
}
body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: 550ms;
}
body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: 600ms;
}
body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0;
}
body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: 600ms;
}
body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: 650ms;
}
body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0;
}
body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: 650ms;
}
body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: 700ms;
}
body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0;
}
body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: 700ms;
}
body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: 750ms;
}
body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0;
}
body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: 750ms;
}
body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: 800ms;
}
body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0;
}
body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: 800ms;
}
body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: 850ms;
}
body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0;
}
body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: 850ms;
}
body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: 900ms;
}
body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0;
}
body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: 900ms;
}
body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: 950ms;
}
body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0;
}
body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: 950ms;
}
body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1000ms;
}
body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0;
}
body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1000ms;
}
body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1050ms;
}
body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0;
}
body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1050ms;
}
body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1100ms;
}
body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0;
}
body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1100ms;
}
body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1150ms;
}
body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0;
}
body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1150ms;
}
body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1200ms;
}
body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0;
}
body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1200ms;
}
body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1250ms;
}
body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0;
}
body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1250ms;
}
body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1300ms;
}
body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0;
}
body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1300ms;
}
body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1350ms;
}
body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0;
}
body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1350ms;
}
body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1400ms;
}
body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0;
}
body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1400ms;
}
body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1450ms;
}
body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0;
}
body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1450ms;
}
body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1500ms;
}
body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0;
}
body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1500ms;
}
body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1550ms;
}
body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0;
}
body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1550ms;
}
body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1600ms;
}
body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0;
}
body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1600ms;
}
body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1650ms;
}
body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0;
}
body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1650ms;
}
body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1700ms;
}
body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0;
}
body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1700ms;
}
body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1750ms;
}
body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0;
}
body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1750ms;
}
body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1800ms;
}
body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0;
}
body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1800ms;
}
body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1850ms;
}
body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0;
}
body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1850ms;
}
body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1900ms;
}
body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0;
}
body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1900ms;
}
body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1950ms;
}
body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0;
}
body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1950ms;
}
body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2000ms;
}
body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0;
}
body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2000ms;
}
body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2050ms;
}
body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0;
}
body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2050ms;
}
body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2100ms;
}
body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0;
}
body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2100ms;
}
body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2150ms;
}
body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0;
}
body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2150ms;
}
body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2200ms;
}
body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0;
}
body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2200ms;
}
body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2250ms;
}
body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0;
}
body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2250ms;
}
body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2300ms;
}
body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0;
}
body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2300ms;
}
body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2350ms;
}
body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0;
}
body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2350ms;
}
body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2400ms;
}
body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0;
}
body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2400ms;
}
body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2450ms;
}
body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0;
}
body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2450ms;
}
body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2500ms;
}
body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0;
}
body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2500ms;
}
body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2550ms;
}
body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0;
}
body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2550ms;
}
body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2600ms;
}
body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0;
}
body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2600ms;
}
body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2650ms;
}
body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0;
}
body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2650ms;
}
body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2700ms;
}
body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0;
}
body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2700ms;
}
body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2750ms;
}
body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0;
}
body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2750ms;
}
body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2800ms;
}
body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0;
}
body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2800ms;
}
body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2850ms;
}
body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0;
}
body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2850ms;
}
body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2900ms;
}
body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0;
}
body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2900ms;
}
body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2950ms;
}
body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0;
}
body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2950ms;
}
body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3000ms;
}
body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0;
}
body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3000ms;
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}
[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}
[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}
[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}
[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}
[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}
body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

#logo {
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 700;
  width: 4.5rem;
  height: 4.5rem;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transition: background-image 1.3s ease;
  -moz-transition: background-image 1.3s ease;
  -o-transition: background-image 1.3s ease;
  transition: background-image 1.3s ease;
}

.bg-green #logo,
.bg-blue #logo,
.bg-violet #logo {
  background-image: url("../Images/logo-white.svg");
}

.bg-yellow #logo,
.bg-white #logo {
  background-image: url("../Images/logo-blue.svg");
}

.burger {
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
  background: #fff;
  border-radius: 50%;
  position: fixed;
  top: 2.75rem;
  right: 2rem;
  z-index: 900;
  width: 3rem;
  height: 3rem;
  padding: 1.4375rem 0.6875rem;
  cursor: pointer;
}
@media (min-width: 800px) {
  .burger {
    right: 3.125rem;
  }
}
.burger span {
  display: block;
  width: 1.625rem;
  height: 0.125rem;
  background: #000;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.burger span::before {
  content: "";
  display: block;
  width: 1.625rem;
  height: 0.125rem;
  background: #000;
  position: relative;
  top: -10px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.burger span::after {
  content: "";
  display: block;
  width: 1.625rem;
  height: 0.125rem;
  background: #000;
  position: relative;
  top: 8px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.burger.clicked {
  background: none;
}
.burger.clicked span {
  background: transparent;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.burger.clicked span::before {
  -webkit-transform: translateY(10px) rotate(45deg);
  -moz-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #fff;
}
.burger.clicked span::after {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -moz-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  -o-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #fff;
}

nav {
  position: fixed;
  overflow-y: auto;
  z-index: 800;
  top: 0;
  right: calc(-100vw - 72px);
  width: calc(100vw - 72px);
  height: 100%;
  background: #031a3a;
  -webkit-transition: right 0.6s cubic-bezier(0.77, 0, 0.175, 1), box-shadow 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: right 0.6s cubic-bezier(0.77, 0, 0.175, 1), box-shadow 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: right 0.6s cubic-bezier(0.77, 0, 0.175, 1), box-shadow 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: right 0.6s cubic-bezier(0.77, 0, 0.175, 1), box-shadow 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
@media (min-width: 800px) {
  nav {
    right: -400px;
    width: 400px;
  }
}
nav ul {
  list-style-type: none;
  padding: 0;
  position: relative;
  z-index: 1;
}
@media (min-width: 800px) {
  nav ul {
    max-width: 360px;
  }
}
@media (min-width: 1200px) {
  nav ul {
    max-width: 300px;
  }
}
nav ul.intended {
  margin-left: 20px;
}
nav ul li {
  margin: 2rem 0;
}
nav ul a {
  color: #fff;
  display: inline-block;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  transition-property: color;
}
nav ul a:hover {
  color: #faff8e;
}
nav ul a.has-subnav-link {
  display: flex;
}
nav ul a.has-subnav-link svg {
  width: 32px;
  height: 32px;
  margin: 0 10px;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -o-transition-property: transform;
  transition-property: transform;
}
nav ul a.has-subnav-link:hover svg {
  transform: translateX(10px);
}

nav.show-nav {
  -webkit-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  right: 0;
}

.subnav {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: calc(-100vw - 72px);
  width: calc(100vw - 72px);
  height: 100%;
  background: #031a3a;
  -webkit-transition: right 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: right 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: right 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: right 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
@media (min-width: 800px) {
  .subnav {
    right: -400px;
    width: 400px;
    background: #102644;
  }
}
.subnav li {
  margin: 1.25rem 0;
}
.subnav a {
  font-size: 18px;
}
.subnav .shortcut a {
  color: #faff8e;
}
.subnav a.has-subnav-link svg {
  width: 28px;
  height: 28px;
}

nav.show-nav .show-subnav > .subnav {
  right: 0;
}

@media (max-width: 767.98px) {
  nav, .subnav {
    padding-top: 7.5rem;
  }
}

.nav-back-links {
  display: flex;
  position: fixed;
  overflow: hidden;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: right 0.5s cubic-bezier(0.77, 0, 0.175, 1), width 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: right 0.5s cubic-bezier(0.77, 0, 0.175, 1), width 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: right 0.5s cubic-bezier(0.77, 0, 0.175, 1), width 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: right 0.5s cubic-bezier(0.77, 0, 0.175, 1), width 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
nav.show-nav .nav-back-links {
  -webkit-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  right: calc(100vw - 72px);
}
@media (min-width: 800px) {
  nav.show-nav .nav-back-links {
    right: 400px;
  }
}
.nav-back-links a {
  background: #031a3a;
  color: #fff;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 24px;
  line-height: 32px;
  padding: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: rotate(180deg);
  white-space: nowrap;
  writing-mode: vertical-lr;
}
.nav-back-links a:hover, .nav-back-links a:focus {
  color: #fff;
}

.lang-select {
  color: #fff;
  position: fixed;
  padding-top: 2.75rem;
  top: 0;
  line-height: 3rem;
  width: calc(75% - 40px);
  z-index: 1;
}
.lang-select a.active-lang,
.lang-select a:hover {
  color: #faff8e;
}
@media (min-width: 768px) {
  .lang-select {
    width: calc(75% - 60px);
  }
}
@media (min-width: 800px) {
  .lang-select {
    width: 340px;
  }
}
@media (min-width: 1200px) {
  .lang-select {
    width: 300px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 750;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 26, 58, 0.5);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.overlay.show {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.breadcrumb {
  display: flex;
  list-style: none;
  font-size: 10px;
  line-height: 20px;
  margin: -50px 0 30px;
  padding: 0;
}
.breadcrumb li {
  display: flex;
}
.breadcrumb li svg {
  height: 11px;
  transform: translateY(-1px);
}
.breadcrumb li span {
  font-weight: bold;
}
.breadcrumb li a {
  text-decoration: underline;
}
.breadcrumb li:not(:last-child):after {
  content: "/";
  padding: 0 7px;
}
@media (min-width: 992px) {
  .breadcrumb {
    font-size: 14px;
    line-height: 25px;
    left: 130px;
    margin: 0;
    position: fixed;
    top: 52px;
    z-index: 700;
  }
  .breadcrumb li svg {
    height: 14px;
  }
  .bg-green .breadcrumb, .bg-blue .breadcrumb, .bg-violet .breadcrumb {
    color: #fff;
  }
  .bg-yellow .breadcrumb, .bg-white .breadcrumb, .bg-white .breadcrumb {
    color: #031a3a;
  }
}

.footer {
  margin-bottom: 0;
}

footer {
  min-height: 50vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  color: #faff8e;
}
@media (max-width: 768px) {
  footer {
    margin-bottom: 2rem;
  }
}
footer .row {
  min-width: 100%;
}
footer .row:first-child {
  align-items: flex-end;
}
footer .row:nth-child(2) {
  align-items: center;
}
footer ul.social {
  list-style-type: none;
  padding: 0;
  text-align: center;
}
@media (min-width: 768px) {
  footer ul.social {
    text-align: left;
    margin-left: -15px;
  }
}
footer ul.social li {
  display: inline-block;
}
footer ul.social li a {
  display: inline-block;
  margin: 0 15px;
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -o-transition-property: transform;
  transition-property: transform;
}
footer ul.social li a.instagram {
  background-image: url(../Images/instagram.svg);
}
footer ul.social li a.facebook {
  background-image: url(../Images/facebook.svg);
}
footer ul.social li a:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
footer ul.legal {
  list-style-type: none;
  padding: 0;
  text-align: center;
}
@media (min-width: 768px) {
  footer ul.legal {
    text-align: right;
    margin-right: -15px;
  }
}
footer ul.legal li {
  display: inline-block;
}
footer ul.legal li a {
  margin: 0 15px;
  color: #fff;
}
footer ul.legal li a:hover {
  text-decoration: underline;
}
footer form {
  position: relative;
  margin-top: 1.25rem;
  margin-bottom: 3.75rem;
}
@media (min-width: 768px) {
  footer form {
    margin-top: 0;
    margin-bottom: 0;
  }
}
footer .input-field {
  width: calc(100% - 30px - 46px);
}
footer label {
  width: 100%;
  z-index: 2;
  display: inline-block;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -o-transition-property: transform;
  transition-property: transform;
  -webkit-transform: translateY(23px);
  -moz-transform: translateY(23px);
  -ms-transform: translateY(23px);
  -o-transform: translateY(23px);
  transform: translateY(23px);
  transform-origin: 0 0;
  -ms-transform-origin: 0 0; /* IE 9 */
  -webkit-transform-origin: 0 0; /* Safari 3-8 */
  cursor: text;
  color: #faff8e;
}
footer label.focused {
  -webkit-transform: translateY(17px) scale(0.9);
  -moz-transform: translateY(17px) scale(0.9);
  -ms-transform: translateY(17px) scale(0.9);
  -o-transform: translateY(17px) scale(0.9);
  transform: translateY(17px) scale(0.9);
}
footer input[type=text] {
  width: 100%;
  z-index: 1;
  background: none;
  border: none;
  border-bottom: 2px solid #faff8e;
  border-radius: 0px;
  outline: none;
  padding: 10px 0px;
  margin: 0;
  color: #fff;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: border-bottom;
  -moz-transition-property: border-bottom;
  -o-transition-property: border-bottom;
  transition-property: border-bottom;
}
footer input[type=text]:focus {
  border-bottom: 2px solid #fff;
}
footer button[type=submit] {
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: 46px;
  height: 46px;
  border-radius: 46px;
  -moz-border-radius: 46px;
  -webkit-border-radius: 46px;
  border: 2px solid #fff;
  background-image: url("../Images/arrow-yellow.svg");
  background-size: 46px 46px;
  background-position: -2px -2px;
  background-color: transparent;
  border-color: #faff8e;
  flex-shrink: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: transform, background-color;
  -moz-transition-property: transform, background-color;
  -o-transition-property: transform, background-color;
  transition-property: transform, background-color;
}
footer button[type=submit]:hover {
  background-color: #faff8e;
  background-image: url("../Images/arrow-blue.svg");
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.eye-catcher {
  max-width: 30vw;
  position: fixed;
  top: 2rem;
  right: 6rem;
  z-index: 1;
}
@media (min-width: 800px) {
  .eye-catcher {
    right: 8.125rem;
  }
}

.klaro {
  --border-radius: 0;
  --white2: #cccc;
}
.klaro .cookie-notice {
  max-width: 700px !important;
}
.klaro .cookie-modal a,
.klaro .cookie-notice a {
  color: #faff8e !important;
}
.klaro .cookie-modal .cm-btn,
.klaro .cookie-notice .cm-btn {
  background: #faff8e !important;
  border-radius: 0 !important;
  color: #031a3a !important;
}
.klaro .cookie-modal .cm-app-input:checked + .cm-app-label .slider,
.klaro .cookie-notice .cm-app-input:checked + .cm-app-label .slider {
  background-color: #00b8a1 !important;
}
.klaro.cm-as-context-notice {
  padding: 0 !important;
}

.embed-responsive [data-type=placeholder] {
  height: 100% !important;
  max-width: 100% !important;
}

.story-teaser {
  display: flex;
  align-items: flex-end;
  position: relative;
  background-size: cover;
  background-position: center center;
  text-decoration: none;
}
.story-teaser:not(.no-shadow) {
  -webkit-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
}
.story-teaser .text-overlay {
  width: 100%;
  background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  color: white;
}
.story-teaser .text-overlay .inner {
  width: 100%;
  padding-right: 72px;
  background-position: calc(100% - 20px) calc(100% - 20px);
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-image: url("../Images/arrow-white.svg");
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: background-position;
  -moz-transition-property: background-position;
  -o-transition-property: background-position;
  transition-property: background-position;
}
.story-teaser:hover .inner {
  background-position: calc(100% - 10px) calc(100% - 20px);
}

.card {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  text-decoration: none;
}
.card .inner {
  width: 100%;
}
a.card .inner {
  padding-right: 72px;
  background-position: calc(100% - 20px) calc(100% - 20px);
  background-size: 32px 32px;
  background-repeat: no-repeat;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: background-position;
  -moz-transition-property: background-position;
  -o-transition-property: background-position;
  transition-property: background-position;
}

a.card.text-green .inner {
  background-image: url("../Images/arrow-green.svg");
}

a.card.text-blue .inner {
  background-image: url("../Images/arrow-blue.svg");
}

a.card.text-yellow .inner {
  background-image: url("../Images/arrow-yellow.svg");
}

a.card.text-violet .inner {
  background-image: url("../Images/arrow-violet.svg");
}

a.card.text-white .inner {
  background-image: url("../Images/arrow-white.svg");
}

a.card:hover .inner {
  text-decoration: none;
  background-position: calc(100% - 10px) calc(100% - 20px);
}

a.card.text-green:hover .inner {
  color: #00b8a1;
}

a.card.text-blue:hover .inner {
  color: #031a3a;
}

a.card.text-yellow:hover .inner {
  color: #faff8e;
}

a.card.text-violet:hover .inner {
  color: #8c30ff;
}

a.card.text-white:hover .inner {
  color: #fff;
}

ul.quicklinks {
  list-style-type: none;
  margin-bottom: 0;
  padding: 1rem 0 0 0;
}
ul.quicklinks li .btn {
  width: 100%;
}

a.instagram-post {
  display: block;
  position: relative;
}
@media (min-width: 1200px) {
  a.instagram-post {
    width: calc(100% + 50px);
  }
}
a.instagram-post img.logo {
  position: absolute;
  bottom: 20px;
  right: 20px;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -o-transition-property: transform;
  transition-property: transform;
}

a.instagram-post:hover img.logo {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

@media (min-width: 1200px) {
  .mod-news-grid a.instagram-post {
    width: calc(100% + 50px);
  }
}

@media (min-width: 1200px) {
  [data-type=placeholder][data-name=instagram] {
    width: calc(100% + 50px);
  }
}

.news-teaser {
  display: block;
}
.news-teaser .inner {
  width: 100%;
  padding-right: 4.5rem;
  background-size: 32px 32px;
  background-position: calc(100% - 20px) calc(100% - 20px);
  background-repeat: no-repeat;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: background-position;
  -moz-transition-property: background-position;
  -o-transition-property: background-position;
  transition-property: background-position;
}
.news-teaser:hover .inner {
  background-position: calc(100% - 10px) calc(100% - 20px);
}

@media (min-width: 1200px) {
  .mod-news-grid .news-teaser {
    width: calc(100% + 50px);
  }
}

/* WITH card background */
.news-teaser.w-image-card,
.news-teaser.no-image-card {
  color: #031a3a;
}
.news-teaser.w-image-card .inner,
.news-teaser.no-image-card .inner {
  background-image: url("../Images/arrow-blue.svg");
}

.news-teaser.no-image-card {
  display: flex;
  align-items: flex-end;
  height: 100%;
  min-height: 15rem;
}

.news-teaser.w-image-card {
  background-color: #fff;
}

.news-teaser.w-image-card:hover .inner,
.news-teaser.no-image-card:hover .inner {
  background-position: calc(100% - 10px) calc(100% - 20px);
}

/* withOUT card background */
.news-teaser.w-image {
  color: #fff;
}
.news-teaser.w-image .inner {
  background-image: url("../Images/arrow-white.svg");
}

/* Load more */
a.load-more {
  display: block;
  color: #faff8e;
  text-decoration: underline;
  text-align: center;
}

a.load-more:hover {
  background: rgba(255, 255, 255, 0.05);
  color: #faff8e;
}

.mod-news-detail .row {
  margin-top: 8rem;
}
@media (min-width: 992px) {
  .mod-news-detail .row {
    margin-top: 16rem;
  }
}
.mod-news-detail .row .intro .date {
  max-width: 640px;
}

.mod-stage {
  position: relative;
  min-height: 500px;
}
@media (min-width: 1200px) {
  .mod-stage {
    height: 100vh;
  }
}
@media (min-width: 1200px) {
  .mod-stage {
    display: -ms-flexbox;
    display: flex;
    min-height: 760px;
  }
}
@media (min-width: 1400px) {
  .mod-stage {
    min-height: 920px;
  }
}
.mod-stage div.left {
  position: relative;
  height: 85vh;
  min-height: 540px;
  max-height: 700px;
}
@media (min-width: 576px) {
  .mod-stage div.left {
    min-height: 660px;
    max-height: 880px;
  }
}
@media (min-width: 768px) {
  .mod-stage div.left {
    min-height: 820px;
    max-height: none;
  }
}
@media (min-width: 992px) {
  .mod-stage div.left {
    height: 100vh;
  }
}
@media (min-width: 1200px) {
  .mod-stage div.left {
    min-height: 100%;
    height: 100%;
    width: 40%;
  }
}
.mod-stage div.left img {
  max-width: 40%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@media (min-width: 1400px) {
  .mod-stage div.left img {
    max-width: 50%;
  }
}
.mod-stage div.left img:not(.no-shadow) {
  -webkit-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
}
.mod-stage div.left .headline {
  position: absolute;
  max-width: 600px;
  bottom: calc(57.1428571429vw - 16px);
  z-index: 2;
}
@media (min-width: 768px) {
  .mod-stage div.left .headline {
    bottom: calc(57.1428571429vw - 20px);
  }
}
@media (min-width: 1200px) {
  .mod-stage div.left .headline {
    bottom: calc(22.8571428571vw - 26px);
  }
}
@media (min-width: 1400px) {
  .mod-stage div.left .headline {
    bottom: calc(28.5714285714vw + 18px);
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
}
.mod-stage div.left .subline {
  z-index: 1;
  width: 60%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.mod-stage div.left .subline p {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .mod-stage div.left .subline p {
    margin-right: 20px;
    max-width: 400px;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .mod-stage div.left .subline {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
@media (min-width: 1400px) {
  .mod-stage div.left .subline {
    width: 50%;
  }
}
.mod-stage div.right {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-height: 100%;
}
@media (max-width: 1400px) {
  .mod-stage div.right {
    padding-top: 50%;
  }
}
@media (min-width: 1200px) {
  .mod-stage div.right {
    width: 60%;
  }
}
.mod-stage div.right .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: -moz-linear-gradient(rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5) 100%);
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5) 100%);
  background: linear-gradient(rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5) 100%);
}
@media (min-width: 1200px) {
  .mod-stage div.right .gradient-overlay {
    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5) 100%);
  }
}
.mod-stage div.right .headline {
  z-index: 2;
  max-width: 600px;
}
.mod-stage div.right .media-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
  background-color: #031a3a;
  background-size: cover;
  background-position: center center;
}
.mod-stage div.right .media-wrapper video {
  display: none;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #031a3a;
  /*
  top: 50%;
  left: 50%;
  @include transform(translateY(-50%) translateX(-50%));
       	height: auto;
       	min-height: 100%;
       	min-width: 100%;
  @media (min-width: $grid-breakpoint-xl) {
        	width: auto;
        	min-width: 100%;
        	min-height: 100%;
  }
  */
}
@media (min-width: 768px) {
  .mod-stage div.right .media-wrapper video {
    display: block;
  }
}
.mod-stage div.right a.btn-arrow {
  background-image: url("../Images/arrow-white.svg");
  border-color: #fff;
}
.mod-stage div.right a.btn-arrow:hover {
  background-color: #fff;
  background-image: url("../Images/arrow-blue.svg");
}

.mod-campaign-stage .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.mod-campaign-stage .stickers img {
  position: absolute;
  max-width: 200px;
}
.mod-campaign-stage .stickers img:nth-child(1) {
  left: 20%;
  top: 20%;
}
.mod-campaign-stage .stickers img:nth-child(2) {
  right: 10%;
  top: 25%;
}
.mod-campaign-stage .stickers img:nth-child(3) {
  left: 10%;
  bottom: 15%;
}
.mod-campaign-stage .stickers img:nth-child(4) {
  right: 8%;
  bottom: 20%;
}
@media (max-width: 767.98px) {
  .mod-campaign-stage .stickers img {
    max-width: 120px;
  }
  .mod-campaign-stage .stickers img:nth-child(1) {
    left: 5%;
  }
  .mod-campaign-stage .stickers img:nth-child(2) {
    right: 2%;
  }
  .mod-campaign-stage .stickers img:nth-child(3) {
    left: 2%;
  }
  .mod-campaign-stage .stickers img:nth-child(4) {
    right: 8%;
  }
}

.mod-statement {
  display: flex;
  align-items: center;
}
.mod-statement .row {
  align-items: flex-end;
}
.mod-statement .display-1, .mod-statement .display-2, .mod-statement .display-3 {
  line-height: 1.6;
}
@media (min-width: 992px) {
  .mod-statement .sidenote {
    margin-bottom: 1rem;
  }
}
.mod-statement u {
  padding-bottom: 0.3em;
  background-position: 0 100%;
  background-size: 28px 12px;
  background-repeat: repeat-x;
  text-decoration: none;
  animation-duration: 1.5s;
  animation-name: movingunderline;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.mod-statement div.btn-wrapper {
  display: inline-block;
}
@media (min-width: 992px) {
  .mod-statement div.btn-wrapper {
    margin-top: 1rem;
    margin-bottom: 0.625rem;
  }
}

@keyframes movingunderline {
  from {
    background-position: 0 100%;
  }
  to {
    background-position: 28px 100%;
  }
}
.mod-statement.highlight-green i {
  color: #00b8a1;
}
.mod-statement.highlight-green u {
  background-image: url("../Images/wave-green.svg");
}

.mod-statement.highlight-blue i {
  color: #031a3a;
}
.mod-statement.highlight-blue u {
  background-image: url("../Images/wave-blue.svg");
}

.mod-statement.highlight-yellow i {
  color: #faff8e;
}
.mod-statement.highlight-yellow u {
  background-image: url("../Images/wave-yellow.svg");
}

.mod-statement.highlight-violet i {
  color: #8c30ff;
}
.mod-statement.highlight-violet u {
  background-image: url("../Images/wave-violet.svg");
}

.mod-statement.highlight-white i {
  color: #fff;
}
.mod-statement.highlight-white u {
  background-image: url("../Images/wave-white.svg");
}

.mod-image-text-teaser .image-col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.mod-image-text-teaser .image-col img:not(.no-shadow) {
  -webkit-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
}
.mod-image-text-teaser .image-col div:first-child {
  width: calc(60% - 10px);
  text-align: right;
}
@media (min-width: 1400px) {
  .mod-image-text-teaser .image-col div:first-child {
    width: calc(65% - 10px);
  }
}
.mod-image-text-teaser .image-col div:first-child img:first-child {
  max-width: calc(100% + 20px);
  margin-left: -20px;
}
@media (min-width: 768px) {
  .mod-image-text-teaser .image-col div:first-child img:first-child {
    max-width: calc(100% + 30px);
    margin-left: -30px;
  }
}
@media (min-width: 1200px) {
  .mod-image-text-teaser .image-col div:first-child img:first-child {
    max-width: calc(100% + 50px);
    margin-left: -50px;
  }
}
.mod-image-text-teaser .image-col div:first-child img:nth-child(2) {
  margin-top: 20px;
  width: 60%;
}
.mod-image-text-teaser .image-col div:nth-child(2) {
  width: calc(40% - 10px);
}
@media (min-width: 1400px) {
  .mod-image-text-teaser .image-col div:nth-child(2) {
    width: calc(35% - 10px);
  }
}
.mod-image-text-teaser .image-col div:nth-child(2) img {
  margin-top: 60px;
}
@media (min-width: 576px) {
  .mod-image-text-teaser .image-col div:nth-child(2) img {
    margin-top: 60px;
  }
}
@media (min-width: 768px) {
  .mod-image-text-teaser .image-col div:nth-child(2) img {
    margin-top: 80px;
  }
}
@media (min-width: 992px) {
  .mod-image-text-teaser .image-col div:nth-child(2) img {
    margin-top: 60px;
  }
}
@media (min-width: 1200px) {
  .mod-image-text-teaser .image-col div:nth-child(2) img {
    margin-top: 120px;
  }
}
@media (min-width: 1400px) {
  .mod-image-text-teaser .image-col div:nth-child(2) img {
    margin-top: 200px;
  }
}
.mod-image-text-teaser .text-col .subline .rte {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .mod-image-text-teaser .text-col .subline .rte {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .mod-image-text-teaser .text-col .subline p {
    margin-right: 20px;
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .mod-image-text-teaser .text-col .subline {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

[class*=mod-intro] .subline {
  max-width: 640px;
}

@media (min-width: 992px) {
  .mod-intro-full-height .row,
.mod-intro-w-image .row {
    min-height: 100vh;
  }
}
.mod-intro-full-height .row .text-col,
.mod-intro-w-image .row .text-col {
  align-self: center;
}

.mod-intro-full-height .row {
  margin-top: 6rem;
}
@media (min-width: 992px) {
  .mod-intro-full-height .row {
    margin-top: 0;
  }
}

.mod-intro-w-image .image-col {
  min-height: 440px;
}
@media (min-width: 992px) {
  .mod-intro-w-image .image-col {
    min-height: 100vh;
  }
}
.mod-intro-w-image .image-col .image {
  background-size: cover;
  background-position: center center;
  width: calc(100% + 40px);
  margin-left: -20px;
  height: 100%;
}
@media (min-width: 768px) {
  .mod-intro-w-image .image-col .image {
    width: calc(100% + 60px);
    margin-left: -30px;
    min-height: 560px;
  }
}
@media (min-width: 992px) {
  .mod-intro-w-image .image-col .image {
    width: calc(100% + 100px);
    margin-left: -50px;
  }
}

.mod-intro-dynamic-height .row {
  margin-top: 8rem;
}
@media (min-width: 992px) {
  .mod-intro-dynamic-height .row {
    margin-top: 16rem;
  }
}

.mod-grid [class*=col] {
  display: flex;
  flex-direction: column;
}
.mod-grid.align-center .row [class*=col] {
  justify-content: center;
}

.mod-news-grid .row {
  align-items: center;
}
.mod-news-grid [class*=col] {
  display: flex;
  flex-direction: column;
}
.mod-news-list [class*=col] {
  margin-bottom: 3.75rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
@media (min-width: 768px) {
  .mod-news-list [class*=col] {
    padding: 0 1.25rem;
  }
}
@media (min-width: 1200px) {
  .mod-news-list [class*=col] {
    padding: 0 2.5rem;
  }
}
.mod-image {
  text-align: center;
}
.mod-image img:not(.no-shadow) {
  -webkit-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 45px 0px rgba(0, 0, 0, 0.3);
}
.mod-quote {
  text-align: center;
}
.mod-quote u {
  padding-bottom: 0.3em;
  background-position: 0 100%;
  background-size: 28px 12px;
  background-repeat: repeat-x;
  text-decoration: none;
}
.mod-q-a p, .mod-q-a img {
  margin-bottom: 1.25rem;
}
@media (max-width: 768px) {
  .mod-q-a .question {
    margin-top: 2.75rem;
    margin-bottom: 1.25rem;
  }
}

.accordion-header {
  position: relative;
  cursor: pointer;
}

.accordion-header:after {
  content: "";
  width: 1rem;
  height: 1rem;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(0deg);
  -moz-transform: translateY(-50%) rotate(0deg);
  -ms-transform: translateY(-50%) rotate(0deg);
  -o-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -o-transition-property: transform;
  transition-property: transform;
}

.accordion-item.active > .accordion-header:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.accent-green .accordion-header {
  color: #00b8a1;
}
.accent-green .accordion-header:after {
  background-image: url("../Images/chevron-green.svg");
}

.accent-blue .accordion-header {
  color: #031a3a;
}
.accent-blue .accordion-header:after {
  background-image: url("../Images/chevron-blue.svg");
}

.accent-yellow .accordion-header {
  color: #faff8e;
}
.accent-yellow .accordion-header:after {
  background-image: url("../Images/chevron-yellow.svg");
}

.accent-violet .accordion-header {
  color: #8c30ff;
}
.accent-violet .accordion-header:after {
  background-image: url("../Images/chevron-violet.svg");
}

.accent-white .accordion-header {
  color: #fff;
}
.accent-white .accordion-header:after {
  background-image: url("../Images/chevron-white.svg");
}

.accordion-body {
  display: none;
}

.event {
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;
}
@media (min-width: 576px) {
  .event {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 576px) {
  .event .event-description {
    width: 60%;
  }
}
@media (min-width: 576px) {
  .event .event-info {
    width: 30%;
    max-width: 200px;
  }
}
.event .event-info ul {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}
@media (max-width: 576px) {
  .event .event-info ul {
    margin-top: 1.25rem;
  }
}
.event .event-info ul li {
  margin: 10px 0;
  padding-left: 22px;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 0px 2px;
}
.event .event-info ul li.date {
  background-image: url("../Images/calendar-white.svg");
}
.event .event-info ul li.location {
  background-image: url("../Images/pin-white.svg");
}
.event .event-info ul li.organizer {
  background-image: url("../Images/person-white.svg");
}
.event .event-info ul li.public {
  background-image: url("../Images/eye-white.svg");
}
.event .event-info ul li.private {
  background-image: url("../Images/eye-stroke-white.svg");
}
@-ms-viewport {
  width: device-width;
}
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Chivo", Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #031a3a;
  transition: background-color 1s ease;
  overflow-x: hidden;
}

p {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
}

.read-more[aria-expanded=true] {
  margin-bottom: 1.25rem;
}
.read-more[data-readmore] {
  display: block;
  width: 100%;
}
.read-more[data-readmore] {
  transition: height 100ms;
  overflow: hidden;
}

.spam-protection--at:before {
  content: "@";
}
.spam-protection--dot:before {
  content: ".";
}
.spam-protection__inner {
  display: inline-block;
  height: 0;
  overflow: hidden;
  width: 0;
}