.footer {
	margin-bottom: 0;
}
footer {
	min-height: 50vh;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	@media (max-width: $grid-breakpoint-md) {
		margin-bottom: 2rem;
	}
	.row {
		min-width: 100%;
	}
	color: $yellow;
	.row:first-child {
		align-items: flex-end;
	}
	.text-small {
		@extend %mt-xs;
	}
	.newsletter-sub {
		@extend %mt-xl;
		@extend %mb-xl;
	}


	.row:nth-child(2) {
		align-items: center;
	}
	ul.social {
		list-style-type: none;
		padding: 0;
		text-align: center;
		@media (min-width: $grid-breakpoint-md) {
			text-align: left;
			margin-left: -15px;
		}
		@extend %mb-l;
		li {
			display: inline-block;
			a {
				display: inline-block;
				margin: 0 15px;
				width: 40px;
				height: 40px;
				background-size: 40px 40px;
				background-repeat: no-repeat;
				@include transition-timing;
				@include transition-property(transform);
			}
			a.instagram {
				background-image: url(#{$image-base-uri}instagram.svg);
			}
			a.facebook {
				background-image: url(#{$image-base-uri}facebook.svg);
			}
			a:hover {
				@include transform(scale(1.2));
			}
		}
	}
	ul.legal {
		list-style-type: none;
		padding: 0;
		text-align: center;
		@media (min-width: $grid-breakpoint-md) {
			text-align: right;
			margin-right: -15px;
		}
		@extend %mb-l;
		li {
			display: inline-block;
			a {
				margin: 0 15px;
				color: $white;
				@extend %text-small;
			}
			a:hover {
				text-decoration: underline;
			}
		}
	}


	// Newsletter
	form {
		position: relative;
			margin-top: 1.25rem;
			margin-bottom: 3.75rem;
		@media (min-width: $grid-breakpoint-md) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	.input-field {
		width: calc( 100% - 30px - 46px );
	}
	label {
		width: 100%;
		z-index: 2;
		display: inline-block;
		@include transition-timing;
		@include transition-property(transform);
		@include transform(translateY(23px));
		transform-origin:          0 0;
		-ms-transform-origin:      0 0; /* IE 9 */
		-webkit-transform-origin:  0 0; /* Safari 3-8 */
		cursor: text;
		color: $yellow;
		@extend %text-small;
	}
	label.focused {
		@include transform(translateY(17px) scale(0.9));
	}
	label.focused {
	}
	input[type=text] {
		width: 100%;
		z-index: 1;
		background: none;
		border: none;
		border-bottom: 2px solid $yellow;
		border-radius: 0px;
		outline: none;
		padding: 10px 0px;
		margin: 0;
		color: $white;
		@include transition-timing;
		@include transition-property(border-bottom);
	}
	input[type=text]:focus {
		border-bottom: 2px solid $white;
	}
	button[type=submit] {
		position: absolute;
		right: 0;
		bottom: 0;
		display: inline-block;
		width: 46px;
		height: 46px;
		@include border-radius(46px);
		border: 2px solid $white;
		background-image: url('#{$image-base-uri}arrow-yellow.svg');
		background-size: 46px 46px;
		background-position: -2px -2px;
		background-color: transparent;
		border-color: $yellow;
		flex-shrink: 0;
		@include transition-timing;
		@include transition-property(transform, background-color);
	}
	button[type=submit]:hover {
		background-color: $yellow;
		background-image: url('#{$image-base-uri}arrow-blue.svg');
		@include transform(scale(1.2));
	}

}