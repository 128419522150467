.mod-quote {
	text-align: center;
	u {
		padding-bottom: 0.3em;
		background-position: 0 100%;
		background-size: 28px 12px;
		background-repeat: repeat-x;
		text-decoration: none;
	}
	.author {
		@extend %mt-s;
	}
}