// White Text Button With Arrow

.btn {
	display: inline-block;
	background: $white;
	@include border-radius(46px);
	@include box-shadow;
	padding: 11px 72px 11px 30px;
	font-weight: 700;
	text-decoration: none;
	background-position: calc( 100% - 20px ) center;
	background-size: 32px 32px;
	background-repeat: no-repeat;
	@include transition-timing;
	@include transition-property(background-position);
}
a.btn:hover {
	text-decoration: none;
	background-position: calc( 100% - 10px ) center;
}

[data-color="green"],
.bg-green {
	.btn {
		color: $green;
	}
  a.btn {
    background-image: url('#{$image-base-uri}arrow-green.svg');
  }
}
[data-color="blue"],
.bg-blue {
	.btn {
		color: $blue;
	}
  a.btn {
    background-image: url('#{$image-base-uri}arrow-blue.svg');
  }
}
[data-color="yellow"],
.bg-yellow {
	.btn {
		color: $blue;
	}
  a.btn {
    background-image: url('#{$image-base-uri}arrow-blue.svg');
  }
}
[data-color="violet"],
.bg-violet {
	.btn {
		color: $violet;
	}
  a.btn {
    background-image: url('#{$image-base-uri}arrow-violet.svg');
  }
}
[data-color="white"],
.bg-white {
	.btn {
		color: $green;
	}
  a.btn {
    background-image: url('#{$image-base-uri}arrow-green.svg');
  }
}



// Round Arrow Button

.btn-arrow {
	display: inline-block;
	width: 46px;
	height: 46px;
	@include border-radius(46px);
	border: 2px solid $white;
	background-image: url('#{$image-base-uri}arrow-white.svg');
	background-size: 46px 46px;
	background-position: -2px -2px;
	flex-shrink: 0;
	@include transition-timing;
	@include transition-property(transform, background-color);
}
a.btn-arrow:hover {
	background-color: $white;
	background-image: url('#{$image-base-uri}arrow-blue.svg');
	@include transform(scale(1.2));
}

.text-green {
	.btn-arrow {
		background-image: url('#{$image-base-uri}arrow-green.svg');
		border-color: $green;
	}
	a.btn-arrow:hover {
		background-color: $green;
	}
}
.text-blue {
	.btn-arrow {
		background-image: url('#{$image-base-uri}arrow-blue.svg');
		border-color: $blue;
	}
	a.btn-arrow:hover {
		background-color: $blue;
	}
}
.text-yellow {
	.btn-arrow {
		background-image: url('#{$image-base-uri}arrow-yellow.svg');
		border-color: $yellow;
	}
	a.btn-arrow:hover {
		background-color: $yellow;
	}
}
.text-violet {
	.btn-arrow {
		background-image: url('#{$image-base-uri}arrow-violet.svg');
		border-color: $violet;
	}
	a.btn-arrow:hover {
		background-color: $violet;
	}
}
.text-white {
	.btn-arrow {
		background-image: url('#{$image-base-uri}arrow-white.svg');
		border-color: $white;
	}
	a.btn-arrow:hover {
		background-color: $white;
	}
}

[data-color="green"],
.bg-green {
	a.btn-arrow:hover {
		background-image: url('#{$image-base-uri}arrow-green.svg');
	}
}
[data-color="blue"],
.bg-blue {
	a.btn-arrow:hover {
		background-image: url('#{$image-base-uri}arrow-blue.svg');
	}
}
[data-color="yellow"],
.bg-yellow {
	a.btn-arrow:hover {
		background-image: url('#{$image-base-uri}arrow-yellow.svg');
	}
}
[data-color="violet"],
.bg-violet {
	a.btn-arrow:hover {
		background-image: url('#{$image-base-uri}arrow-violet.svg');
	}
}
[data-color="white"],
.bg-white {
	a.btn-arrow:hover {
		background-image: url('#{$image-base-uri}arrow-white.svg');
	}
}