.mod-image-text-teaser {
	.image-col {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: flex-start;
    img:not(.no-shadow) {
			@include box-shadow;
		}
		div:first-child {
			width: calc( 60% - 10px );
			@media (min-width: $grid-breakpoint-xxl) {
				width: calc( 65% - 10px );
			}
			text-align: right;
			img:first-child {
				max-width: calc( 100% + 20px );
				margin-left: -20px;
				@media (min-width: $grid-breakpoint-md) {
					max-width: calc( 100% + 30px );
					margin-left: -30px;
				}
				@media (min-width: $grid-breakpoint-xl) {
					max-width: calc( 100% + 50px );
					margin-left: -50px;
				}
			}
			img:nth-child(2) {
				margin-top: 20px;
				width: 60%;
			}
		}
		div:nth-child(2) {
			width: calc( 40% - 10px );
			@media (min-width: $grid-breakpoint-xxl) {
				width: calc( 35% - 10px );
			}
			img {
				margin-top: 60px;
				@media (min-width: $grid-breakpoint-sm) {
					margin-top: 60px;
				}
				@media (min-width: $grid-breakpoint-md) {
					margin-top: 80px;
				}
				@media (min-width: $grid-breakpoint-lg) {
					margin-top: 60px;
				}
				@media (min-width: $grid-breakpoint-xl) {
					margin-top: 120px;
				}
				@media (min-width: $grid-breakpoint-xxl) {
					margin-top: 200px;
				}
			}
		}
	}
	.text-col {
		@extend %pt-xl;
		@extend %pb-xl;
		.headline {
				@extend %mb-s;
		}
		.subline {
			.rte {
				margin-bottom: 1rem;
				@media (min-width: $grid-breakpoint-md) {
					margin-bottom: 0;
				}
			}
			p {
				@media (min-width: $grid-breakpoint-md) {
					margin-right: 20px;
					max-width: 640px;
				}
			}
			@media (min-width: $grid-breakpoint-md) {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
			}
		}
	}
}