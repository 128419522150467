.mod-statement {
	display: flex;
	align-items: center;
	.row {
		align-items: flex-end;
	}
	.display-1, .display-2, .display-3 {
		line-height: 1.6;
	}
	.sidenote {
  		@extend %text-small;
  		@extend %mt-m;
		@media (min-width: $grid-breakpoint-lg) {
  			margin-bottom: 1rem;
  		}
	}
	u {
		padding-bottom: 0.3em;
		background-position: 0 100%;
		background-size: 28px 12px;
		background-repeat: repeat-x;
		text-decoration: none;
		animation-duration: 1.5s;
		animation-name: movingunderline;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
	div.btn-wrapper {
		display: inline-block;
		@extend %mt-m;
		@media (min-width: $grid-breakpoint-lg) {
			margin-top: 1rem;
			margin-bottom: 0.625rem;
		}
	}
}


@keyframes movingunderline {
  from {
		background-position: 0 100%;
  }

  to {
		background-position: 28px 100%;
  }
}


.mod-statement.highlight-green {
	i {
		color: $green;
	}
	u {
		background-image: url("#{$image-base-uri}wave-green.svg");
	}
}
.mod-statement.highlight-blue {
	i {
		color: $blue;
	}
	u {
		background-image: url("#{$image-base-uri}wave-blue.svg");
	}
}
.mod-statement.highlight-yellow {
	i {
		color: $yellow;
	}
	u {
		background-image: url("#{$image-base-uri}wave-yellow.svg");
	}
}
.mod-statement.highlight-violet {
	i {
		color: $violet;
	}
	u {
		background-image: url("#{$image-base-uri}wave-violet.svg");
	}
}
.mod-statement.highlight-white {
	i {
		color: $white;
	}
	u {
		background-image: url("#{$image-base-uri}wave-white.svg");
	}
}