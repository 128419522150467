.mod-image {
	text-align: center;
	img:not(.no-shadow) {
		@include box-shadow;
	}
	.image-caption {
		@extend %text-small;
		@extend %mt-xs;
	}
}
